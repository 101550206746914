import { validate, ValidatorOptions } from "class-validator";
import { plainToClass } from "class-transformer";
import { ValidationErrors } from "./validationErrors";

const defaultOptions = {
	validationError: { target: false },
	whitelist: true,
	forbidNonWhitelisted: true,
	forbidUnknownValues: false,
};
export const validateObject = async (obj: any, validatorOptions?: ValidatorOptions): Promise<void> => {
	const customOptions = validatorOptions || {};
	const options = Object.assign({}, defaultOptions, customOptions);
	const errors = await validate(obj, options);

	if (errors.length > 0) {
		throw new ValidationErrors(errors);
	}
};

const defaultOptionsV2 = {
	validationError: { target: false },
	whitelist: true,
	forbidNonWhitelisted: true,
	forbidUnknownValues: true,
};
export const validateObjectV2 = async (obj: any, validatorOptions?: ValidatorOptions): Promise<void> => {
	const customOptions = validatorOptions || {};
	const options = Object.assign({}, defaultOptionsV2, customOptions);
	const errors = await validate(obj, options);

	if (errors.length > 0) {
		throw new ValidationErrors(errors);
	}
};

export const validateAndTransformACPInputParams = async (obj: any, dtoClass: any): Promise<void | any> => {
	const dtoInstance = plainToClass(dtoClass, obj);
	const errors = await validate(dtoInstance);

	if (errors.length > 0) {
		throw new ValidationErrors(errors);
	}
	return dtoInstance;
};
