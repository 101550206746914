import { Checkbox } from "@lifesg/react-design-system/checkbox";
import { dateToString } from "app/common/date";
import { ICheckboxItem } from "app/components/basic/Checkbox";
import NricInput from "app/components/basic/NricInput";
import { useEwillsForm } from "app/modules/eWills/hooks/useEWillsForm";
import { Form, IFormFunctions, useForm } from "app/hooks/useForm";
import { IConstraint, validateInputValue } from "app/hooks/useForm/Form.Validation";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { BeneficiaryKeys, Relationship } from "../../../Beneficiaries/data";
import { ExecutorsKey, SelectExecutorErrors } from "../../data";
import { AdditionalExecutorSchema, NricErrors } from "../common/schema";
import { ErrorMessage } from "../common/styles";

interface IExecutorCheckboxContent {
	form: IFormFunctions;
	/** to identify child index */
	index: string;
	/** list selected by parent */
	selectList: string[];
	/** passed from checkbox.tsx when rendering component */
	setOverwriteContentError?: Dispatch<SetStateAction<boolean>>;
	disabled?: boolean;
	item?: ICheckboxItem;
	toggleItem?: () => void;
	checked?: boolean;
	itemValue?: string;
	labelLines?: number;
	className?: string;
}

export const ExecutorCheckboxContent = ({
	form: parentForm,
	checked,
	index,
	disabled,
	item,
	itemValue,
	labelLines,
	selectList,
	toggleItem,
	className,
}: IExecutorCheckboxContent): JSX.Element => {
	const {
		personalDetails,
		setExecutor,
		executors,
		setExecutorNric,
		executorNric,
		isLoading,
		beneficiariesNricMap,
		replacementExecutor,
	} = useEwillsForm();
	const { form } = useForm();
	const [renderOtherRelation, setRenderOtherRelation] = useState(false);
	const [isNricLoaded, setIsNricLoaded] = useState(false);
	const [error, setError] = useState("");
	/**
	 * setup useeffect
	 */
	useEffect(() => {
		const executor = executors[index];
		if (executor) {
			form.setupFormFields(AdditionalExecutorSchema(personalDetails?.uinfin ?? ""), {
				[BeneficiaryKeys.DOB]: executor.details?.dob,
				[BeneficiaryKeys.NAME]: executor.details?.name,
				[BeneficiaryKeys.NRIC]: executor.details?.nric,
				[BeneficiaryKeys.OTHER_RELATIONSHIP]: executor.details?.otherRelationship,
				[BeneficiaryKeys.RELATIONSHIP]: executor.details?.relationship,
			});
		} else {
			form.setupFormFields(AdditionalExecutorSchema(personalDetails?.uinfin ?? ""));
			setIsNricLoaded(true);
		}

		form.forceWarnIfExit(false);
	}, []);

	const beneficiariesNricList = useMemo(() => {
		return Object.keys(beneficiariesNricMap);
	}, [beneficiariesNricMap]);

	// reset custom error on checkbox set
	useEffect(() => {
		setError("");
	}, [checked]);

	// additional validator for child form when parent form is validated
	useEffect(() => {
		function setParentForm() {
			parentForm.updateFieldValue(ExecutorsKey.ADDITIONAL_EXECUTORS, executors);
		}

		function prevalidator() {
			setError("");
			return true;
		}

		function validateChildForm() {
			if (!selectList.includes(index)) {
				setError(SelectExecutorErrors.ADDED_BUT_NOT_SELECTED);
				return false;
			}
			if (form.validateForm()) {
				return true;
			}
			return false;
		}
		if (Object.keys(form.fields).length) {
			parentForm.setAdditionalValidation(validateChildForm, index);
			parentForm.setValidationCallback(setParentForm, undefined, index);
			parentForm.setPrevalidationAction(prevalidator, index);
		}
		return () => {
			parentForm.removeAdditionalValidation(validateChildForm, index);
			parentForm.removeValidationCallback(setParentForm, undefined, index);
			parentForm.removePrevalidationAction(prevalidator, index);
		};
	}, [Object.keys(form.fields).length, executors[index], selectList]);

	// relationship validator
	useEffect(() => {
		if (form.getValue(BeneficiaryKeys.RELATIONSHIP) === Relationship.OTHER) {
			form.setFieldShouldValidate(BeneficiaryKeys.OTHER_RELATIONSHIP, true);
			return setRenderOtherRelation(true);
		}
		form.setFieldShouldValidate(BeneficiaryKeys.OTHER_RELATIONSHIP, false);
		setRenderOtherRelation(false);
	}, [form.fields[BeneficiaryKeys.RELATIONSHIP]]);

	// nric validator - to check for duplicated NRICs from beneficiaries and executors
	useEffect(() => {
		const additionalExecutorNric = { ...executorNric };
		//List of already added nrics, not including the current edited nric
		delete additionalExecutorNric[index];
		const selectOtherReplacementExecutor = additionalExecutorNric[ExecutorsKey.REPLACEMENT_EXECUTOR];
		delete additionalExecutorNric[ExecutorsKey.REPLACEMENT_EXECUTOR];

		const nricList = [...beneficiariesNricList, ...Object.values(additionalExecutorNric)];

		const constraints: IConstraint[] = [
			["isRequired", NricErrors.NO_NRIC],
			["isNRIC", NricErrors.INVALID_NRIC],
			["isNot", personalDetails?.uinfin ?? "", NricErrors.SAME_NRIC_AS_APPLICANT_ERROR_MESSAGE],
			["isUnique", nricList, NricErrors.DUPLICATE_NRIC_ERROR_MESSAGE],
		];
		if (replacementExecutor.key === ExecutorsKey.OTHER_REPLACEMENT_EXECUTOR) {
			constraints.push([
				"isNot",
				selectOtherReplacementExecutor,
				NricErrors.SAME_NRIC_AS_REPLACEMENT_EXECUTOR_ERROR_MESSAGE,
			]);
		}

		form.updateFieldConstraints(BeneficiaryKeys.NRIC, constraints);

		//Reset error message if the duplicated nric in another component is removed
		const field = form.getField(BeneficiaryKeys.NRIC);
		field.constraints = constraints;
		const nric = form.getValue(BeneficiaryKeys.NRIC) as string;
		if (!nricList.includes(nric) && validateInputValue(field) === "" && !!executors[index]) {
			setExecutorNric(index, nric);
			form.resetFieldError(BeneficiaryKeys.NRIC);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [executors, beneficiariesNricList, executorNric, replacementExecutor]);

	useEffect(() => {
		//If the nric is being loaded from context, the nric would be undefined until the form is loaded
		if (isNricLoaded) {
			const isValid = validateInputValue(form.getField(BeneficiaryKeys.NRIC)) === "" && !!executors[index];
			setExecutorNric(index, isValid ? (form.getValue(BeneficiaryKeys.NRIC) as string) : "");
		} else {
			setIsNricLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.fields[BeneficiaryKeys.NRIC]?.value, form.errors[BeneficiaryKeys.NRIC]]);

	useEffect(() => {
		if (Object.keys(form.fields).length === 0) {
			return;
		}
		setExecutor({
			key: index,
			type: "additionalExecutors",
			details: {
				name: form.getValue(BeneficiaryKeys.NAME),
				nric: form.getValue(BeneficiaryKeys.NRIC),
				dob: form.getValue(BeneficiaryKeys.DOB),
				otherRelationship: form.getValue(BeneficiaryKeys.OTHER_RELATIONSHIP),
				relationship: form.getValue(BeneficiaryKeys.RELATIONSHIP),
			},
		});
	}, [
		form.fields[BeneficiaryKeys.NRIC]?.value,
		form.fields[BeneficiaryKeys.NAME]?.value,
		form.fields[BeneficiaryKeys.DOB]?.value,
		form.fields[BeneficiaryKeys.RELATIONSHIP]?.value,
		form.fields[BeneficiaryKeys.OTHER_RELATIONSHIP]?.value,
	]);

	const maxDate = dateToString(new Date());
	return (
		<>
			<div>
				<div
					key={`${index}__checkbox`}
					data-testid={`${index}__checkbox`}
					className={`${className} overwrite-checkbox__container`}
				>
					<div className="overwrite-checkbox__container-wrapper">
						<div className="overwrite-checkbox-container-header">
							<div className="overwrite-checkbox__container--clickable" onClick={toggleItem}>
								<Checkbox
									disabled={disabled}
									id={index}
									className={"checkbox__item__button"}
									name={index}
									value={itemValue}
									checked={checked}
									displaySize={"small"}
								/>
								<label
									className={
										item?.labelBold
											? "checkbox__option-title "
											: "checkbox__option-label " + (labelLines ? "truncate" : "")
									}
									style={{ WebkitLineClamp: labelLines }}
								>
									{" "}
									Someone else
								</label>
							</div>
							{item?.onRemove && (
								<button
									disabled={isLoading}
									className="overwrite-checkbox__remove-button"
									onClick={() => {
										item?.onRemove && item?.onRemove();
									}}
								>
									Remove
								</button>
							)}
						</div>

						<div className={`overwrite-checkbox__container--content ${error ? "error" : ""}`} key={index}>
							<Form.Input
								form={form}
								field={BeneficiaryKeys.NAME}
								title="Full name (as in NRIC)"
								disabled={isLoading}
							/>
							<NricInput
								computerWidth={6}
								mobileWidth={12}
								tabletWidth={12}
								form={form}
								field={BeneficiaryKeys.NRIC}
								title="NRIC number"
								disabled={isLoading || !isNricLoaded}
								index={index}
							/>
							<Form.Date
								form={form}
								field={BeneficiaryKeys.DOB}
								title="Date of birth"
								computerWidth={6}
								mobileWidth={12}
								tabletWidth={12}
								disabled={isLoading}
								maxDate={maxDate}
								withButton={false}
							/>
							<Form.Dropdown
								form={form}
								field={BeneficiaryKeys.RELATIONSHIP}
								title="Relationship to you"
								computerWidth={6}
								mobileWidth={12}
								tabletWidth={12}
								disabled={isLoading}
							/>
							{renderOtherRelation && (
								<Form.Input
									form={form}
									field={BeneficiaryKeys.OTHER_RELATIONSHIP}
									computerWidth={6}
									mobileWidth={12}
									tabletWidth={12}
									title="How is this person related to you?"
									subtitle="This makes it easier to identify the right person."
									disabled={isLoading}
								/>
							)}
						</div>
					</div>
				</div>
				{error && <ErrorMessage>{error}</ErrorMessage>}
			</div>
		</>
	);
};
