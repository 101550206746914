import { CrossIcon, MenuIcon } from "@lifesg/react-icons";
import Router from "next/router";
import { useEffect, useRef, useState } from "react";
import "./NavbarDrawer.scss";

interface IProps {
	navigationLinks: JSX.Element;
	closeTerm: string;
	isVault?: boolean;
}

const NavbarDrawer = (props: IProps): JSX.Element => {
	const toggleButtonRef = useRef<HTMLButtonElement>(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const [isOpen, setOpen] = useState(false);
	const [isLoading, setLoaded] = useState(true);
	const openDrawer = (): void => setOpen(true);
	const closeDrawer = (): void => setOpen(false);

	Router.events.on("routeChangeStart", closeDrawer);

	window.onload = (): void => {
		setLoaded(false);
	};

	useEffect(() => {
		const nextBody = document.getElementById("__next");
		if (nextBody) {
			if (isOpen) {
				nextBody.classList.add("navbar-drawer--open");
			} else {
				nextBody.classList.remove("navbar-drawer--open");
			}
		}
	});

	useEffect(() => {
		if (!closeButtonRef.current || !toggleButtonRef.current) {
			return;
		}

		if (isOpen) {
			closeButtonRef.current.focus();
		} else {
			toggleButtonRef.current.focus();
		}
	}, [isOpen]);

	return (
		<>
			<button
				className="navbar-drawer__toggle"
				id="navbar-drawer__toggle"
				aria-label="Open navigation drawer"
				aria-expanded={isOpen}
				ref={toggleButtonRef}
				onClick={openDrawer}
			>
				<MenuIcon />
			</button>
			<div
				className={`navbar-drawer navbar-drawer--${isOpen ? "open" : "close"} navbar-drawer--${
					props.isVault ? "vault" : "public"
				}`}
			>
				<div
					className={`navbar-drawer__content navbar-drawer__content--${isOpen ? "open" : "close"} ${
						isLoading ? "loading" : ""
					}`}
				>
					<button
						className="navbar-drawer__close-toggle"
						tabIndex={0}
						id="navbar-drawer__close-toggle"
						onClick={closeDrawer}
						ref={closeButtonRef}
					>
						<span className="navbar-drawer__close-toggle__text">{props.closeTerm}</span>
						<div className="navbar-drawer__close-icon">
							<CrossIcon />
						</div>
					</button>
					<div
						className="navbar-drawer__navigation-links"
						onBlur={(event) => {
							// if tabbing away from the last nav-link, set the focus back to the close button
							if (
								event.target.parentNode &&
								Array.from(event.target.parentNode.children).indexOf(event.target) ===
									event.target.parentNode.children.length - 1
							) {
								event.stopPropagation();

								if (closeButtonRef.current) {
									closeButtonRef.current.focus();
								}
							}
						}}
					>
						{props.navigationLinks}
					</div>
				</div>
				<div
					onClick={closeDrawer}
					className={`navbar-drawer__overlay navbar-drawer__overlay--${isOpen ? "open" : "close"}`}
				/>
			</div>
		</>
	);
};

export default NavbarDrawer;
