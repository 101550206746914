import styled from "styled-components";

// do not replace these styles, unless there is a issue with masthead breakpoint changes by sgds
export const MastheadContainer = styled.div`
	background-color: #f0f0f0;

	@media screen and (min-width: 1028px) {
		margin-left: calc(-100vw + 960px + 60px);
	}
	@media screen and (min-width: 1200px) {
		margin-left: calc(-100vw + 1140px + 60px);
	}
	@media screen and (min-width: 1400px) {
		margin-left: calc(-100vw + 1320px + 60px);
	}

	@media screen and (min-width: 1500px) {
		margin-left: -9.5rem;
	}
`;

export const NavbarContainer = styled.div`
	z-index: 99;
	background-color: white;
	position: sticky;
	top: 0;
`;
