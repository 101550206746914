import { isUserAgeBelowThreshold } from "app/common/date";
import { IRadioBoxItem } from "app/components/basic/Radio.Box";
import { IExecutor } from "./data";

export const eligibleBeneficiaries = (replacementExecutorList: Map<string, IExecutor>): IRadioBoxItem[] => {
	if (replacementExecutorList.size > 0) {
		return Array.from(replacementExecutorList.keys())?.flatMap((executor) => {
			const executorDetails = replacementExecutorList.get(executor);
			if (!executorDetails || !executorDetails.details) {
				return [];
			}
			const { year, month, day } = executorDetails?.details?.dob || {};
			if (executorDetails.type === "beneficary" && !isUserAgeBelowThreshold(new Date(+year, +month, +day), 21)) {
				return [{ label: executorDetails.details.name, value: executorDetails.details.nric }];
			}
			return [];
		}) as IRadioBoxItem[];
	} else {
		return [];
	}
};
