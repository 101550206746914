import Modal from "app/components/basic/Modal";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect } from "react";
import { IFullAppointedPerson } from "../common/interfaces";
import { IAppointPersonModal } from "../common/utils";

const NAME = "name";
const NRIC = "nric";
const BIRTH_DATE = "birthDate";
const RELATIONSHIP = "relationship";
const PHONE = "phone";
const EMAIL = "email";
const POSTAL_CODE = "postalCode";
const FLOOR = "floor";
const UNIT = "unit";
const STREET_NAME = "streetName";

const fields: IFormSchema = {
	[NAME]: { type: "text", maxChar: 120, constraints: [["isRequired", "Full name cannot be empty"]] },
	[NRIC]: { type: "text", maxChar: 9, constraints: [["isNRICShape", "Please enter a valid NRIC/FIN/Passport no."]] },
	[BIRTH_DATE]: { type: "date", constraints: [["isTodayOrBefore", "Date must be before today"]] },
	[RELATIONSHIP]: { type: "text", maxChar: 50, constraints: [["isRequired", "Relationship cannot be empty"]] },
	[PHONE]: {
		type: "text",
		maxChar: 8,
		constraints: [["isLocalPhoneNumber", "Please enter a valid phone number."]],
	},
	[EMAIL]: {
		type: "text",
		maxChar: 120,
		constraints: [["isEmail", "Please enter a valid email"]],
	},
	[POSTAL_CODE]: {
		type: "text",
		maxChar: 6,
		constraints: [
			["isNumberString", "Postal code must be a number"],
			["isMinLength", 6, "Please enter a valid postal code"],
		],
	},
	[FLOOR]: { type: "text", maxChar: 2, constraints: [["isAlphanumeric", "Please enter a valid floor"]] },
	[UNIT]: { type: "text", maxChar: 4, constraints: [["isAlphanumeric", "Please enter a valid unit"]] },
	[STREET_NAME]: { type: "text", maxChar: 120 },
};

const NewReplacementModal = ({
	personIndex,
	personList,
	closeCallback,
	updatePersonList,
}: IAppointPersonModal<IFullAppointedPerson>): JSX.Element => {
	const { form } = useForm();
	const { setupFormFields, warnChangesLostIfProceed, getValue } = form;

	const initFormModal = useCallback((): void => {
		if (undefined === personIndex) {
			return setupFormFields(fields, {}, NAME);
		}

		const person: IFullAppointedPerson = personList[personIndex];
		const initialData = {
			[NAME]: person.name,
			[NRIC]: person.nric,
			[BIRTH_DATE]: person.birthDate,
			[RELATIONSHIP]: person.relationship,
			[PHONE]: person.phone,
			[EMAIL]: person.email,
			[POSTAL_CODE]: person.postalCode,
			[FLOOR]: person.floor,
			[UNIT]: person.unit,
			[STREET_NAME]: person.streetName,
		};
		setupFormFields(fields, initialData, NAME);
	}, [personIndex, personList, setupFormFields]);

	const closeModal = useCallback((): void => {
		warnChangesLostIfProceed(() => closeCallback());
	}, [warnChangesLostIfProceed, closeCallback]);

	const handleUpdatePersonList = useCallback(async (): Promise<void> => {
		const newOrUpdatedPerson: IFullAppointedPerson = {
			name: getValue(NAME),
			nric: getValue(NRIC),
			birthDate: getValue(BIRTH_DATE),
			relationship: getValue(RELATIONSHIP),
			phone: getValue(PHONE),
			email: getValue(EMAIL),
			postalCode: getValue(POSTAL_CODE),
			floor: getValue(FLOOR),
			unit: getValue(UNIT),
			streetName: getValue(STREET_NAME),
		};

		const updatedList: IFullAppointedPerson[] = personList;
		if (undefined !== personIndex) {
			updatedList[personIndex] = newOrUpdatedPerson; // for editing
		} else {
			updatedList.push(newOrUpdatedPerson); // for new
		}

		updatePersonList(updatedList);
		closeCallback();
	}, [getValue, closeCallback, personIndex, personList, updatePersonList]);

	useEffect(() => initFormModal(), [initFormModal]);

	return (
		<Modal
			id="modal__add-coordinator"
			form={form}
			type="form"
			title="Add replacement"
			isOpen={true}
			closeCallback={closeModal}
			button1={["Cancel", "secondary", closeModal]}
			button2={["Add", "primary", handleUpdatePersonList]}
		>
			<div style={{ marginTop: -24 }}>
				<Form.Input title="Full name as NRIC/FIN/Passport" field={NAME} form={form} />
				<div className="coordinator__form__row">
					<Form.Input
						title="NRIC/FIN/Passport no."
						field={NRIC}
						form={form}
						computerWidth={6}
						mobileWidth={12}
						transformUppercase={true}
					/>
				</div>
				<Form.Date
					title="Date of birth"
					computerWidth={6}
					mobileWidth={12}
					accessibleLabel="birth"
					field={BIRTH_DATE}
					form={form}
				/>
				<Form.Input
					title="Relationship to me"
					field={RELATIONSHIP}
					form={form}
					computerWidth={6}
					mobileWidth={12}
				/>
				<Form.Input title="Phone number" field={PHONE} form={form} computerWidth={6} mobileWidth={12} />
				<Form.Input title="Email address" field={EMAIL} form={form} />
				<div className="mt32" />
				<div role="group" aria-labelledby="replacement__mailing-address">
					<small id="replacement__mailing-address">Local mailing address</small>
					<div className="child-spacing-24">
						<Form.Input field={STREET_NAME} title="Street name" form={form} />
					</div>
					<div className="coordinator__form__row">
						<Form.Input title="Floor" field={FLOOR} form={form} computerWidth={4} mobileWidth={12} />
						<Form.Input title="Unit" field={UNIT} form={form} computerWidth={4} mobileWidth={12} />
						<Form.Input
							title="Postal code"
							field={POSTAL_CODE}
							form={form}
							computerWidth={6}
							mobileWidth={12}
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default NewReplacementModal;
