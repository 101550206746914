import { Navbar } from "@lifesg/react-design-system/navbar";
import { Text } from "@lifesg/react-design-system/text";
import { PersonFillIcon } from "@lifesg/react-icons/person-fill";
import { Menu, MenuButton, MenuItem, MenuList } from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import translations from "app/assets/files/translations/navbar.json";
import BasicLink from "app/components/basic/Link";
import SearchbarLabel from "app/components/basic/SearchbarLabel/SearchbarLabel";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import { useEwillsForm } from "app/modules/eWills/hooks/useEWillsForm";
import { useHdbParkingForm } from "app/modules/hdb-car-parking/hooks/useHdbParkingForm";
import { getCookie } from "app/utils/cookies";
import { LanguageISOCode, useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { ITranslatedObject } from "app/utils/internationalization/TranslationPageRenderer";
import { deleteSid } from "app/utils/redis";
import { setLogout, withAuthRedirect } from "app/utils/url";
import NextLink from "next/link";
import Router from "next/router";
import { useEffect, useState } from "react";
import { showSimplifiedHeaderAndFooter } from "../util";
import { LogoMyLegacySimple } from "./LogoMyLegacySimple";
import NavLink from "./NavLink";
import NavbarDrawer from "./Navbar.Drawer";
import "./Navbar.scss";
import { baseEndOfLifeUrl } from "app/components/templates/Guide/__common__/Topic";

interface IProps {
	notifications?: number;
}

const MAX_NAVINK_WIDTH = {
	en: {
		HOME: 156,
		EOLP: 120,
		WDH: 120,
		VAULT: 156,
	},
	ml: {
		HOME: 80,
		EOLP: 130,
		WDH: 165,
		VAULT: 160,
	},
	zh: {
		HOME: 156,
		EOLP: 156,
		WDH: 156,
		VAULT: 156,
	},
	ta: {
		HOME: 110,
		EOLP: 254,
		WDH: 140,
		VAULT: 145,
	},
};

const NavbarPublic = (props: IProps): JSX.Element => {
	const [, selectedLanguage] = useGlobalLanguageState();
	const [navText, setNavText] = useState<ITranslatedObject[]>(translations["en"]);
	const [isDesktop, setIsDesktop] = useState<boolean>();
	const { isSingpassFlow } = useEwillsForm();
	const { sid } = useHdbParkingForm();
	const { pathname } = window.location;

	// Check for ACP 3.0
	const isSimpleHeader = showSimplifiedHeaderAndFooter(pathname);

	useEffect(() => {
		window.addEventListener("resize", onWindowResize);
		return (): void => {
			window.removeEventListener("resize", onWindowResize);
		};
	});

	useEffect(() => {
		// set isDesktop on load
		onWindowResize();
	}, []);

	useEffect(() => {
		const newText = translations[selectedLanguage];
		setNavText(newText);
	}, [selectedLanguage, window.location.pathname]);

	const onWindowResize = () => {
		if (window.innerWidth >= 1024) {
			// MEDIA QUERIES $medium-width: 1024px;
			setIsDesktop(true);
			return;
		}
		setIsDesktop(false);
	};

	const isActive = (path: string): boolean => {
		return window.location.pathname.includes(path);
	};

	const isHome = (): boolean => {
		const paths = window.location.pathname.split("/").filter((value: string) => {
			return value !== "";
		});
		return paths.length === 0;
	};

	const getNameFromCookie = (): string => {
		const name = getCookie("name");
		if (name) {
			return name;
		}
		return "UNKNOWN_NAME";
	};

	const logoutHandler = async () => {
		await deleteSid(sid);
		setLogout();
	};

	const getNavigationLinks = (isMobile: boolean): JSX.Element => {
		return (
			<>
				<NavLink
					isMobile={isMobile}
					title={navText[0].translation}
					href="/"
					active={isHome()}
					maxWidth={MAX_NAVINK_WIDTH[selectedLanguage].HOME}
				/>
				<NavLink
					isMobile={isMobile}
					title={navText[1].translation}
					href={baseEndOfLifeUrl}
					active={isActive(baseEndOfLifeUrl)}
					maxWidth={MAX_NAVINK_WIDTH[selectedLanguage].EOLP}
				/>
				<NavLink
					isMobile={isMobile}
					title={navText[2].translation}
					href="/when-death-happens"
					active={isActive("/when-death-happens")}
					maxWidth={MAX_NAVINK_WIDTH[selectedLanguage].WDH}
				/>
				<NavLink
					isMobile={isMobile}
					title={navText[3].translation}
					href="/vault"
					active={isActive("/vault")}
					maxWidth={MAX_NAVINK_WIDTH[selectedLanguage].VAULT}
				/>
				{isMobile && (
					<NavLink
						title={<SearchbarLabel />}
						isMobile={isMobile}
						href="/results"
						active={isActive("/results")}
					/>
				)}
			</>
		);
	};

	const renderNavbarAvatar = (notifications?: number): JSX.Element => {
		const notificationCountLabel = notifications ? (notifications > 9 ? "9+" : String(notifications)) : "";
		return (
			<div className="avatar">
				<Menu>
					<MenuButton aria-label="User menu">
						<div id="navbar__avatar__toggle" className="navbar__avatar__button">
							<PersonFillIcon />
						</div>
					</MenuButton>

					<MenuList portal={false}>
						<div className="navbar__avatar-profile-name" aria-hidden={true}>
							<span>{getNameFromCookie()}</span>
						</div>

						<MenuItem onSelect={() => void Router.push("/vault/profile")}>
							<div id="navlink--profile" className="navbar__avatar-text">
								<span className="text">{navText[8].translation}</span>
							</div>
						</MenuItem>
						<MenuItem onSelect={() => void Router.push("/vault/notifications")}>
							<div id="navlink--notifications" className="navbar__avatar-text with-border">
								<span className="text">{navText[9].translation}</span>
								{notificationCountLabel && (
									<span className="description">{notificationCountLabel}</span>
								)}
							</div>
						</MenuItem>
						<MenuItem onSelect={() => void Router.push("/vault/settings")}>
							<div id="navlink--settings" className="navbar__avatar-text with-border">
								<span className="text">{navText[10].translation}</span>
							</div>
						</MenuItem>
						<MenuItem onSelect={logoutHandler}>
							<div id="navlink--log-out" className="navbar__avatar-text with-border">
								<span className="text">{navText[11].translation}</span>
							</div>
						</MenuItem>
					</MenuList>
				</Menu>

				{notifications !== undefined && notifications > 0 && (
					<span className="navbar__avatar__notification-count-label">{notificationCountLabel}</span>
				)}
			</div>
		);
	};

	const loginButton = (): JSX.Element => {
		const expiry = getCookie("expiry");

		const sessionStatus = determineSessionStatus(expiry);
		if (sessionStatus === SessionStatusType.active) {
			return (
				<div className="navbar__vault__content__right">
					<div className="navbar__avatar">{renderNavbarAvatar(props.notifications)}</div>
				</div>
			);
		}

		return (
			<>
				<BasicLink
					id="navbar__button__login"
					onClick={() => {
						if (isSingpassFlow !== undefined) {
							sessionStorage.clear();
						}
						window.location.href = withAuthRedirect("/vault");
					}}
					name={navText[6].translation}
					type="secondary-link-responsive"
					disablePrefetch={true}
					isAPIRoute={true}
				/>
			</>
		);
	};

	const UserName = () => {
		return (
			<>
				<Text.H4 weight={"semibold"} style={{ paddingLeft: "1.25rem", paddingRight: "1rem" }}>
					{getNameFromCookie().toUpperCase()}
				</Text.H4>
				<hr />
			</>
		);
	};

	const mobileWithSimpleHeader = (): JSX.Element => {
		return (
			<Navbar
				id="navbar__content_simplified"
				items={{
					desktop: [],
					mobile: [
						{
							itemType: "component",
							children: <UserName />,
						},
						{
							href: "/vault/profile",
							id: "navlink--profile",
							children: navText[8].translation,
						},
						{
							id: "navlink--notifications",
							children: navText[9].translation,
							href: "/vault/notifications",
						},
						{
							id: "navlink--settings",
							children: navText[10].translation,
							href: "/vault/settings",
						},
					],
				}}
				resources={{
					primary: {
						brandName: "myLegacy",
						logoSrc: "/images/simplified-logo.svg",
					},
				}}
				actionButtons={{
					desktop: [],
					mobile: [
						{
							type: "button",
							args: {
								styleType: "secondary",
								children: "Logout",
								onClick: () => {
									logoutHandler();
								},
							},
						},
					],
				}}
				masthead={false}
			/>
		);
	};

	return (
		<>
			{!isDesktop && isSimpleHeader ? (
				mobileWithSimpleHeader()
			) : (
				<div className="navbar navbar__public" lang={LanguageISOCode[selectedLanguage]}>
					<div className={`navbar__content ${isSimpleHeader ? "navbar__content__simple" : ""}`}>
						<div className="navbar__content__left">
							{!isDesktop && (
								<NavbarDrawer
									navigationLinks={getNavigationLinks(true)}
									closeTerm={navText[7].translation}
								/>
							)}
							<NextLink href="/" passHref={true} prefetch={true}>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<a id="navbar__public__logo" className="navbar__logo">
									{isSimpleHeader ? (
										<LogoMyLegacySimple />
									) : (
										<img
											src={"/images/site-logo.png"}
											id="navbar__icon--public"
											className="navbar__icon"
											alt="My Legacy - Home"
										/>
									)}
								</a>
							</NextLink>
							{isDesktop && !isSimpleHeader && (
								<div className="navbar__navigation-links navbar__public__navigation-links">
									{getNavigationLinks(false)}
								</div>
							)}
						</div>
						<div className="navbar__content__right">
							{isDesktop && !isSimpleHeader && <NavLink title={<SearchbarLabel />} href="/results" />}
							{loginButton()}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default NavbarPublic;
