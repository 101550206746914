import { registerDecorator, ValidationArguments, ValidationOptions } from "class-validator";
import { validateUinfin } from "@wog/mol-lib-api-contract/utils";

// tslint:disable-next-line: ban-types
export function IsDocumentNumberShape(
	property: string | null,
	validationOptions?: ValidationOptions,
): (object: Object, propertyName: string) => void {
	// tslint:disable-next-line: ban-types
	return (object: Object, propertyName: string): void => {
		registerDecorator({
			name: "isDocumentNumberShape",
			target: object.constructor,
			propertyName,
			constraints: [],
			options: validationOptions,
			validator: {
				validate(value: string, args: ValidationArguments): boolean {
					const documentType =
						property && args.object && args.object.hasOwnProperty(property) ? args.object[property] : null;
					return validateDocumentNumberShape(documentType, value);
				},
				defaultMessage(args: ValidationArguments): string {
					return `${args.value} is not a valid document number.`;
				},
			},
		});
	};
}

export const validateDocumentNumberShape = (documentType: string, value: string): boolean => {
	if (!value || value.toString().trim().length > 9) {
		return false;
	}
	const alphaNumericPattern = /^[A-Z0-9]*$/;

	if (documentType === "NRIC/FIN") {
		return alphaNumericPattern.test(value) && validateUinfin(value);
	}

	return true;
};
