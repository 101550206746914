import { ChevronRightIcon } from "@lifesg/react-icons/chevron-right";
import { validateUinfin } from "@wog/mol-lib-api-contract/utils/data/sg-uinfin";
import { Redactor } from "@wog/mol-lib-api-contract/utils/redactor/Redactor";
import { DeathRecordType } from "app/common/api/deathCert";
import { IdentificationType } from "app/common/api/deathCert/enums";
import Checkbox from "app/components/basic/Checkbox";
import BasicLink from "app/components/basic/Link";
import ReadOnlyField from "app/components/common/ReadOnlyField";
import { DeathCertFormContext, DeathCertFormData } from "app/components/templates/DeathCert/Forms/DeathCertFormContext";
import ErrorMessage from "app/components/widgets/ErrorMessage/ErrorMessage";
import { Form, IFormField, IFormFunctions } from "app/hooks/useForm";
import { forwardRef, useContext } from "react";
import { Grid } from "semantic-ui-react";
import { FormKeys } from "./schemas";
import "./style.scss";

export const NRIC_REGEX = /^[ST]\d{7}[A-Z]/;
export const STEPS_TITLE = ["Personal details", "Relationship", "Review"];

//================================================================================================
// Validations
//================================================================================================
export type ValidationResult = {
	key: string;
	error: string | JSX.Element;
};

type ValidationFn = (form: IFormFunctions) => ValidationResult[];

export const validateAtLeastOneContactDetail: ValidationFn = (form: IFormFunctions) => {
	const email: string = form.getValue(FormKeys.EMAIL) as string;
	const phone: string = form.getValue(FormKeys.PHONE) as string;
	if (email || phone) {
		return [];
	}
	const errorMsg = "You must enter either your mobile number or email address to proceed.";
	return [
		{ key: FormKeys.EMAIL, error: errorMsg },
		{ key: FormKeys.PHONE, error: errorMsg },
	];
};

export const validateIdentificationIfNric: ValidationFn = (form: IFormFunctions) => {
	const identificationType: string = form.getValue(FormKeys.IDENTIFICATION_TYPE) as string;
	if (identificationType === IdentificationType.PASSPORT) {
		return [];
	}
	const identificationNumber: string = form.getValue(FormKeys.IDENTIFICATION_NUMBER) as string;
	const isNricOrFin = validateUinfin(identificationNumber);
	const isNric = NRIC_REGEX.test(identificationNumber); // make sure nric is not fin
	if (
		(identificationType === IdentificationType.NRIC && (!isNric || !isNricOrFin)) ||
		(identificationType === IdentificationType.FIN && (isNric || !isNricOrFin))
	) {
		return [{ key: FormKeys.IDENTIFICATION_NUMBER, error: `Invalid ${identificationType}. Try again.` }];
	}

	return [];
};

export const checkValidationResults = (form: IFormFunctions, validationResults: ValidationResult[]): boolean => {
	if (validationResults?.length) {
		for (const { key, error } of validationResults) {
			form.updateFieldError(key, error);
		}
		return false;
	}
	return true;
};

export const checkAtLeastOneContactDetails = async (form: IFormFunctions): Promise<boolean> => {
	form.validateFieldValue(form.getField(FormKeys.PHONE));
	form.validateFieldValue(form.getField(FormKeys.EMAIL));
	return checkValidationResults(form, validateAtLeastOneContactDetail(form));
};

//================================================================================================
// Utils
//================================================================================================

export const isStillBirth = (context: DeathCertFormData): boolean =>
	context.formResponses[FormKeys.CERT_TYPE] === DeathRecordType.Stillbirth;

interface ContactProps {
	form: IFormFunctions;
	onBlurHandler?: (formField?: IFormField) => void;
	disableBlurValidation?: boolean;
}
//================================================================================================
// Render Form Functions
//================================================================================================

export const EmailForm = (props: ContactProps): JSX.Element => {
	const { form, disableBlurValidation } = props;
	return (
		<Form.Input
			title="Email address"
			field={FormKeys.EMAIL}
			form={form}
			disableBlurValidation={disableBlurValidation}
			additionalValidations={() => checkAtLeastOneContactDetails(props.form)}
			computerWidth={6}
			mobileWidth={12}
		/>
	);
};

export const MobileNumberForm = (props: ContactProps): JSX.Element => {
	const { form, disableBlurValidation } = props;

	return (
		/* must use Input instead of PhoneNumber as we want to display masked numbers */
		<Form.Input
			title="Mobile number"
			prefix="+65"
			field={FormKeys.PHONE}
			disableBlurValidation={disableBlurValidation}
			form={form}
			additionalValidations={() => checkAtLeastOneContactDetails(props.form)}
			computerWidth={6}
			mobileWidth={12}
		/>
	);
};

const checkIdentificationBasedOnType = async (form: IFormFunctions): Promise<boolean> => {
	form.validateFieldValue(form.getField(FormKeys.IDENTIFICATION_NUMBER));
	return await checkValidationResults(form, validateIdentificationIfNric(form));
};

export const IdentificationDetailsForm = (props: {
	form: IFormFunctions;
	isUserLoggedIn: boolean;
	onIdNumberChanged?: () => void;
}): JSX.Element => {
	const { form, isUserLoggedIn, onIdNumberChanged } = props;

	if (isUserLoggedIn) {
		const name: string = form.getValue(FormKeys.NAME) as string;
		const identificationNumber: string = form.getValue(FormKeys.IDENTIFICATION_NUMBER) as string;
		return (
			<>
				<ReadOnlyField id="readonly-field__requestor-name" title="Full Name (as in NRIC or FIN)" value={name} />

				<ReadOnlyField
					id="readonly-field__requestor-nricfin"
					title="NRIC or FIN"
					value={Redactor.UINFIN_REDACTOR.redactValue(identificationNumber ?? "")}
				/>
			</>
		);
	}

	return (
		<>
			<Form.Dropdown
				title="Identification type"
				computerWidth={6}
				mobileWidth={12}
				field={FormKeys.IDENTIFICATION_TYPE}
				form={form}
				afterSelectItem={() => void checkIdentificationBasedOnType(form)}
			/>
			<Form.Input
				title="Full name (as in identification document)"
				placeholder="For example, Lim Koh Pi"
				computerWidth={6}
				mobileWidth={12}
				field={FormKeys.NAME}
				form={form}
			/>
			<Form.Input
				title="Identification number"
				placeholder="For example, S1234567A"
				computerWidth={6}
				mobileWidth={12}
				field={FormKeys.IDENTIFICATION_NUMBER}
				onBlurHandler={onIdNumberChanged}
				form={form}
				transformUppercase
				additionalValidations={() => checkIdentificationBasedOnType(form)}
			/>
		</>
	);
};

export const RequestorDetailsForm = (props: {
	form: IFormFunctions;
	isUserLoggedIn: boolean;
	onIdNumberChanged?: () => void;
}): JSX.Element => {
	const { form, isUserLoggedIn, onIdNumberChanged } = props;

	const renderHeader = () => (
		<>
			<h5>Your details</h5>
			<p className="death-cert-form__instructions">Provide at least one way to contact you.</p>
		</>
	);

	return (
		<>
			{renderHeader()}
			<IdentificationDetailsForm
				form={form}
				isUserLoggedIn={isUserLoggedIn}
				onIdNumberChanged={onIdNumberChanged}
			/>
			<MobileNumberForm form={form} />
			<EmailForm form={form} />
		</>
	);
};

const sameAsAboveOption = "Same as above";
const recordNotFoundText =
	"Record not found. If the details you’ve entered are accurate, you may need to try again 24 hours later.";

interface DeceasedDetailsFormProps {
	form: IFormFunctions;
	sameAsAbove: boolean;
	onSetSameAsAbove: (boolean) => void;
	shouldDisplayRecordNotFoundMsg: boolean;
}

export const DeceasedDetailsForm = forwardRef<HTMLDivElement, DeceasedDetailsFormProps>(
	(props, scrollToErrorRef): JSX.Element => {
		const { form, sameAsAbove, onSetSameAsAbove, shouldDisplayRecordNotFoundMsg } = props;
		const context = useContext(DeathCertFormContext);
		const stillBirth = isStillBirth(context);

		// copies value at time of changing `sameAsAbove` checkbox
		const selectedValuesHandler = (newValues: string[]) => {
			const selected = newValues.includes(sameAsAboveOption);
			onSetSameAsAbove(selected);
			form.updateFieldValue(
				FormKeys.DEC_IDENTIFICATION_NUMBER,
				selected ? form.getValue(FormKeys.IDENTIFICATION_NUMBER) : "",
			);
		};

		const RightArrowIcon = (): JSX.Element => <ChevronRightIcon />;

		return (
			<>
				<h5 ref={scrollToErrorRef} className="mt32">
					{stillBirth ? "Stillbirth details" : "Deceased’s details"}
				</h5>
				{shouldDisplayRecordNotFoundMsg && (
					<ErrorMessage className="death-cert-form__error-message" type="error" id={"test"}>
						{recordNotFoundText}
						<div className="death-cert-form__related-faq">
							<BasicLink
								id="death-cert-form-read-related-faq"
								name="Read related FAQ"
								type="inline-link"
								href="/frequently-asked-questions#i-tried-downloading-the-death-or-stillbirth-certificate-but-was-told-that-the-record-was-not-found-what-should-i-do-"
								IconRight={RightArrowIcon}
								className="death-cert-form__related-faq-link"
							/>
						</div>
					</ErrorMessage>
				)}
				{stillBirth && sameAsAbove ? (
					<ReadOnlyField
						id="readonly-field__requestor-nric"
						title="NRIC, FIN, or passport number"
						value={form.getValue(FormKeys.IDENTIFICATION_NUMBER) as string}
					/>
				) : (
					<Form.Input
						title={stillBirth ? "Mother’s NRIC, FIN, or passport number" : "NRIC, FIN, or passport number"}
						subtitle="This should match the identification number recorded by the hospital or doctor."
						placeholder="For example, S1234567A"
						computerWidth={6}
						mobileWidth={12}
						field={FormKeys.DEC_IDENTIFICATION_NUMBER}
						transformUppercase
						form={form}
					/>
				)}

				{stillBirth ? (
					<Grid className="no-margin no-padding">
						<Grid.Row>
							<Grid.Column className="no-margin no-padding" mobile={12} tablet={6} computer={6}>
								<Checkbox
									name="sameAsAbove"
									items={[{ label: sameAsAboveOption }]}
									selectedValues={sameAsAbove ? [sameAsAboveOption] : []}
									updateSelectedValues={selectedValuesHandler}
									className="checkbox mt8"
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				) : null}

				<Form.Input
					title={stillBirth ? "Stillbirth document number" : "Death document number"}
					subtitle="You can get this from the hospital or doctor."
					placeholder={stillBirth ? "For example, 01234A or SB0123" : "For example, 01234A or D01234"}
					computerWidth={6}
					mobileWidth={12}
					field={FormKeys.DEC_CERT_NO}
					transformUppercase
					form={form}
				/>
				<Form.Date
					field={FormKeys.DEC_DEATH_DATE}
					title={stillBirth ? "Date of stillbirth" : "Date of death"}
					accessibleLabel="session"
					form={form}
					computerWidth={6}
					mobileWidth={12}
				/>
			</>
		);
	},
);

export const NotifyUpdateFormRows = (props: { form: IFormFunctions; certType: DeathRecordType }): JSX.Element => {
	const { form, certType } = props;

	return (
		<div className="death-cert-form__form-field">
			<Form.RadioBox
				title={`Notify me if there's an update to the ${certType} certificate`}
				subtitle={`This could be due to corrections or new information regarding the ${certType}.`}
				columns={2}
				form={form}
				field={FormKeys.NOTIFY_UPDATE}
			/>
			<div className="death-cert-form__text--small">
				By submitting. I declare that all information submitted by me is true, accurate and complete to the best
				of my knowledge and belief. Any false, inaccurate or misleading information provided may lead to
				prosecution under the Registration of Births and Deaths Act and/or the Penal Code. Any facilities,
				rights or privileges granted to me may also be withdrawn.
			</div>
		</div>
	);
};

interface EditFormRowsProps {
	form: IFormFunctions;
	certType: DeathRecordType;
}

export const EditFormRows = (props: EditFormRowsProps): JSX.Element => {
	const { form, certType } = props;

	return (
		<>
			<Grid.Row columns={12}>
				<Grid.Column className="no-margin no-padding" mobile={12} tablet={12} computer={12}>
					<IdentificationDetailsForm form={form} isUserLoggedIn={true} />
					<MobileNumberForm form={form} disableBlurValidation={true} />
					<EmailForm form={form} disableBlurValidation={true} />
				</Grid.Column>
				<Grid.Column className="no-margin no-padding mt32" mobile={12} tablet={12} computer={12}>
					<NotifyUpdateFormRows form={form} certType={certType} />
				</Grid.Column>
			</Grid.Row>
		</>
	);
};
