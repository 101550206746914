import { ArrowRightIcon } from "@lifesg/react-icons/arrow-right";
import Link from "next/link";
import "./BasicLink.scss";

export type TLinkTypes =
	| "default"
	| "inline-link"
	| "danger-link"
	| "no-underline-link"
	| "secondary-link-responsive"
	| "button-primary"
	| "button-secondary"
	| "button-disabled";

export interface ILinkProps {
	id: string;
	name?: string;
	href?: string;
	type?: TLinkTypes;
	className?: string;
	hrefAs?: string;
	openInNewPage?: boolean;
	disablePrefetch?: boolean;
	IconLeft?: (props: any) => JSX.Element;
	IconRight?: (props: any) => JSX.Element;
	withRightArrrow?: boolean;
	disableFocus?: boolean;
	fontSize?: number;
	fullWidthOnMobile?: boolean;
	renderAsTel?: boolean;
	keepScrollPosition?: boolean;
	isAPIRoute?: boolean;
	isSmall?: boolean;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const BasicLink = (props: ILinkProps): JSX.Element => {
	let className = `link-${props.type || "default"}`;

	if (props.className) {
		className += ` ${props.className}`;
	}

	if (props.fontSize) {
		switch (props.fontSize) {
			case 14:
				className += " link-font-fourteen";
				break;
			case 16:
				className += " link-font-sixteen";
				break;
			case 18:
				className += " link-font-eighteen";
				break;
			default:
				className += "";
		}
	}

	if (props.fullWidthOnMobile) {
		className += " link-full-width-mobile";
	}

	if (props.isSmall) {
		className += " ds__text__body-small";
	}

	const shouldOpenInANewPage: string | undefined = props.openInNewPage ? "_blank" : "_self";
	const shouldPrefetch: boolean | undefined =
		props.disablePrefetch || props.href?.includes("http") || props.href?.includes("singpass?redirect")
			? false
			: undefined;

	const { id, name, IconLeft, IconRight, withRightArrrow } = props;

	if (props.renderAsTel || props.onClick) {
		return (
			<a
				tabIndex={props.disableFocus ? -1 : 0}
				id={id}
				key={id}
				className={className}
				href={props.href}
				onClick={props.onClick}
			>
				{name}
			</a>
		);
	}

	const anchorElement = (
		<a
			tabIndex={props.disableFocus ? -1 : 0}
			id={id}
			key={id}
			className={className}
			target={shouldOpenInANewPage}
			rel={shouldOpenInANewPage ? "noopener noreferrer" : ""}
			href={props.href}
		>
			{IconLeft && <IconLeft className="link-icon-left" />}
			{name}
			{IconRight && <IconRight className="link-icon-right" />}
			{withRightArrrow && <ArrowRightIcon className={`link-right-arrow ds-icon--size-${props.fontSize || 16}`} />}
		</a>
	);

	return props.isAPIRoute ? (
		anchorElement
	) : (
		<Link
			href={props.href ? props.href : ""}
			as={props.hrefAs}
			passHref={true}
			prefetch={shouldPrefetch}
			scroll={!props.keepScrollPosition}
		>
			{anchorElement}
		</Link>
	);
};

export default BasicLink;
