export const AlertIllustration = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130" fill="none">
		<g clipPath="url(#clip0_686_53065)">
			<path
				d="M54.5133 12.4745C59.1664 4.38869 70.8336 4.3887 75.4867 12.4745L128.37 105.453C133.011 113.519 127.189 123.587 117.883 123.587H12.1172C2.81081 123.587 -3.01136 113.519 1.63046 105.453L54.5133 12.4745Z"
				fill="#FDF2E0"
			/>
			<path
				d="M54.1605 36.5191C54.1337 35.9029 54.626 35.3887 55.2428 35.3887H74.7584C75.3752 35.3887 75.8675 35.9029 75.8407 36.5191L73.5151 90.0087C73.4899 90.5882 73.0128 91.045 72.4328 91.045H57.5684C56.9884 91.045 56.5113 90.5882 56.4861 90.0087L54.1605 36.5191Z"
				fill="#F6C063"
			/>
			<circle cx="65.0007" cy="104.354" r="8.46944" fill="#F6C063" />
		</g>
		<defs>
			<clipPath id="clip0_686_53065">
				<rect width="130" height="130" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
