import { Color } from "@lifesg/react-design-system/color";
import { MediaQuery } from "@lifesg/react-design-system/media";
import { Text } from "@lifesg/react-design-system/text";
import Segment from "app/components/page/Segment";
import { getTruncateStyle } from "app/modules/common/styles";
import styled from "styled-components";

export const FormSegmentLabel = styled.div`
	width: 100%;

	h4 {
		color: ${Color.Neutral[2]};
		margin-bottom: 0.5rem;
	}

	p {
		color: #686868;
	}

	a {
		font-weight: 600;
		font-size: 1rem;
		svg {
			width: 1rem !important;
			height: 1rem !important;
			margin-left: 0.125rem;
		}
	}

	div {
		margin: 0.5rem 0;
	}
`;

export const InfoPanel = styled(Segment)`
	background: ${Color.Neutral[5]};

	> div > * {
		margin: 1rem 0;
		max-width: 55.3125rem;

		${MediaQuery.MinWidth.mobileL} {
			&:first-child {
				margin-top: 2rem;
			}

			&:last-child {
				margin-bottom: 2rem;
			}
		}
	}
	a {
		font-weight: 600;
	}
	padding-top: 0;
`;

export const MainContainer = styled(Segment)`
	padding-top: 2rem;

	> div > div {
		max-width: 55.3125rem;
	}
`;

export const InputWrapper = styled.div`
	margin: 0;
	padding: 0;

	& > .form:first-of-type {
		margin: 0;
	}
`;

export const TextBodyWrapper = styled(Text.Body)`
	margin: 0;
`;

export const InlineTextBodyWrapper = styled(Text.Body)`
	margin: 0;
	display: inline;
`;

export const LinkWithIcon = styled(Text.Hyperlink.Default)`
	display: flex;
	align-items: center;

	& svg {
		margin-left: 0.25rem;
	}
`;

export const ContentWrapper = styled.div`
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const ListName = styled(Text.H5)`
	color: #693d07;
	margin: 0;
	padding: 0;
	${getTruncateStyle()}
`;

export const ListBody = styled(Text.BodySmall)`
	color: #693d07;
`;

export const AlertBannerContainer = styled.div`
	margin: 0.5rem 0 0;
	p,
	h5 {
		color: #693d07;
	}
	ul {
		margin: 0.5rem 0;
		padding-left: 1.5rem;
		li::marker {
			font-size: 0.75rem;
			color: ${Color.Neutral[2]};
		}
		li > * {
			margin-left: 0.5rem;
		}
	}
`;
