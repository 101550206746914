import { CsatType } from "app/common/api/csat/enums";

export enum GTMEvent {
	FORM_START = "form_start",
	FORM_COMPLETE = "form_complete",
	FORM_DOWNLOAD = "form_download",
	FORM_SEND_EMAIL = "form_send_email",
	CSAT_FEEDBACK = "csat_feedback",
}

// -----------------------------------------------------------------------------
// GTM Form Parameters
// -----------------------------------------------------------------------------

export enum GTMFormName {
	DEATH_CERT = "death_cert",
	STILLBIRTH_CERT = "stillbirth_cert",
	LPA_V1 = "lpa_v1",
	ACP_V1 = "acp_v1",
	LPA_ACP_V1 = "lpa_acp_v1",
}

export interface GTMFormBasicParams {
	form_name: GTMFormName;
}

export interface GTMFormDownloadParams extends GTMFormBasicParams {
	is_successful: boolean;
	transaction_id?: string;
}

export interface GTMCsatEventParams {
	csat_rating: number;
	csat_type: CsatType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GTMFormSendEmailParams extends GTMFormDownloadParams {}

// -----------------------------------------------------------------------------

export type GTMEventParams = GTMFormBasicParams | GTMFormSendEmailParams | GTMCsatEventParams;
