import { Text } from "@lifesg/react-design-system/text";
import { MediaQuery } from "@lifesg/react-design-system/media";
import { Modal } from "@lifesg/react-design-system/modal";
import Button from "app/components/basic/Button";
import styled from "styled-components";

export const Content = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 4rem 1.25rem;

	${MediaQuery.MinWidth.tablet} {
		padding: 4rem 4rem;
	}
`;

export const Header = styled.h4`
	text-align: center;
	font-weight: 600;
	margin: 0;
`;

export const Description = styled(Text.BodySmall)`
	font-weight: 400;
	line-height: 1.625rem !important;
	margin: 0.5rem 0 2rem;
	text-align: center;
	white-space: pre-line;
`;

export const CTAWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;

	${MediaQuery.MinWidth.tablet} {
		align-items: center;
		flex-direction: row-reverse;
		gap: 2rem;
		justify-content: center;
	}
`;

export const ModalButton = styled(Button)`
	flex: 1;
	justify-content: center;

	${MediaQuery.MinWidth.tablet} {
		max-width: 16rem;
	}
`;

export const SrollableDiv = styled.div`
	max-height: 100%;
	overflow-y: auto;
	overscroll-behavior: none;
	width: 100%;
`;

export const ModalBox = styled(Modal.Box)`
	margin: 2.5rem auto;
	max-height: fit-content;
`;
