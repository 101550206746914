// Ewills sessionstorage key
export const EwillsSessionStorageKey = "ewills-session-data";
export const PreformSessionStorageKey = "ewills-pre-form";
export const EwillsSessionKeyCookieSecret = "MOLUserId";
export const EwillsSessionKeyPreFormSecret = "preform-key";

// eWills form steps and page title (used by [steps].tsx to render correct page and page title)
export enum FormSteps {
	PERSONAL_DETAILS = "step1",
	BENEFICIARIES = "step2",
	ESTATE_SPECIFIC = "step3",
	RESIDUARY_ESTATE = "step4",
	EXECUTOR = "step5",
	REVIEW = "step6",
}

export const FormTitle: { [key in FormSteps]: string } = {
	step1: "Personal Details",
	step2: "Beneficiaries",
	step3: "Your estate: specific items",
	step4: "Your estate: everything else",
	step5: "Executors",
	step6: "Review",
};

export const eWillsUrl = {
	baseStepUrl: "/find-a-tool/learning-tool-write-a-will/step",
	under21: "/find-a-tool/learning-tool-write-a-will/user-under-21-years-old/",
	landingPage: "/find-a-tool/learning-tool-write-a-will/",
	checkSuitability: "/find-a-tool/learning-tool-write-a-will/check-suitability/",
	termsAndCondition: "/find-a-tool/learning-tool-write-a-will/terms-and-conditions/",
	continueWithSingapass: "/find-a-tool/learning-tool-write-a-will/continue-with-singpass/",
	personalDetails: "/find-a-tool/learning-tool-write-a-will/step1/",
	beneficiary: "/find-a-tool/learning-tool-write-a-will/step2/",
	estateSpecific: "/find-a-tool/learning-tool-write-a-will/step3/",
	everythingElse: "/find-a-tool/learning-tool-write-a-will/step4/",
	executor: "/find-a-tool/learning-tool-write-a-will/step5/",
	review: "/find-a-tool/learning-tool-write-a-will/step6/",
	submit: "/find-a-tool/learning-tool-write-a-will/next-steps/",
};

export const eWillsPreFormStep = {
	landing: 0,
	suitability: 1,
	tnc: 2,
	continue: 3,
	complete: 4,
};

export const eWillsPreFormRediction = {
	0: eWillsUrl.landingPage,
	1: eWillsUrl.checkSuitability,
	2: eWillsUrl.termsAndCondition,
	3: eWillsUrl.continueWithSingapass,
};

export const FormSubtitle: { [key in FormSteps]: string } = {
	step1: "Check that these details from your Myinfo profile are accurate.",
	step2: "Giving details about them helps ensure that the right person is contacted. Try to fill in this section to the best of your knowledge, and make sure that the information is correct.",
	step3: "Decide if there are items you want to give away before the rest of your estate is distributed.",
	step4: "Distribute the rest of your assets and belongings among your beneficiaries.",
	step5: "Choose someone you trust to carry out the wishes in your will after you die.",
	step6: "Make sure all the information you’ve provided is correct.",
};

export const FormSubtitleNonSingpassFlow: { [key in FormSteps]: string } = {
	...FormSubtitle,
	step1: "The details will be used to create your will template.",
};

// eWills next/previous page url (used by cta.tsx to route to correct page after each step)
export const nextStepUrl: { [key in FormSteps]: string } = {
	step1: eWillsUrl.beneficiary,
	step2: eWillsUrl.estateSpecific,
	step3: eWillsUrl.everythingElse,
	step4: eWillsUrl.executor,
	step5: eWillsUrl.review,
	step6: eWillsUrl.submit,
};

export const previousStepUrl: { [key in FormSteps]: string | undefined } = {
	step1: undefined,
	step2: eWillsUrl.personalDetails,
	step3: eWillsUrl.beneficiary,
	step4: eWillsUrl.estateSpecific,
	step5: eWillsUrl.everythingElse,
	step6: eWillsUrl.executor,
};

export const IS_SINGPASS_FLOW_PARAM = "isSingpassFlow";
