export enum BestInterests {
	TalkToLovedOnes = "TalkToLovedOnes",
	BeIndependent = "BeIndependent",
	EnjoyFavouriteFood = "EnjoyFavouriteFood",
	LearnAndGrowSkillsAndKnowledge = "LearnAndGrowSkillsAndKnowledge",
	BeDevoutFollowerOfFaith = "BeDevoutFollowerOfFaith",
	Others = "Others",
}

export enum BestInterestsLabel {
	TalkToLovedOnes = "Being able to talk to my loved ones",
	BeIndependent = "Being independent - moving around and going places by myself",
	EnjoyFavouriteFood = "Being able to enjoy my favourite food",
	LearnAndGrowSkillsAndKnowledge = "Being able to learn and grow my skills and knowledge",
	BeDevoutFollowerOfFaith = "Being a devout follower of my faith",
	Others = "Others",
}

export enum AcpFormV3Status {
	DRAFT = "DRAFT",
	SUBMITTED = "SUBMITTED",
	EXPIRED = "EXPIRED",
}

export enum AcpVersion {
	"ACP_V3" = "ACP_V3",
}
