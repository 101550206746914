import { Color } from "@lifesg/react-design-system/color";
import { NotificationBanner } from "@lifesg/react-design-system/notification-banner";
import { BaseTheme } from "@lifesg/react-design-system/theme";
import styled from "styled-components";
export const NotificationBannerWrapper = styled(NotificationBanner)`
	position: relative;

	> section {
		padding-inline: 1rem !important;

		@media screen and (min-width: 768px) {
			padding-inline: 1.5rem !important;
		}
		@media screen and (min-width: 1024px) {
			padding-inline: 2.5rem !important;
		}
	}
	> section > div {
		padding: 0;
		max-width: calc(100vw);

		@media screen and (min-width: 1024px) {
			max-width: calc(100vw - 80px);
		}

		@media screen and (min-width: 1500px) {
			max-width: 1440px;
		}
	}

	a {
		/* https://jira.ship.gov.sg/browse/MOL-15932- we need to use lifesg colour for this  */
		color: ${Color.Validation.Orange.Icon!({ theme: BaseTheme })} !important;
	}
`;

export const BannerContent = styled.p`
	margin: 0;
`;
