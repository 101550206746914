import { ZonedDateTime } from "@js-joda/core";
import { ITopFearCheckBox, TFears } from "../../pages/form/FearsOrWorriesPage/types";
import { HasHealthConcernValues, THealthConcernStatement } from "../../pages/form/HealthConcernPage/data";
import { HasExperienceValues } from "../../pages/form/PersonalExperiencePage/data";
import { TSourcesOfSupport } from "../../pages/form/SourcesOfSupportPage/types";
import { TActivities } from "../../pages/form/ThingsYouEnjoyPage/types";
import { TGoalsOfCare } from "../../pages/form/GoalsOfCarePage/data";

export interface IPersonalDetails {
	name: string;
	uinfin: string;
	dob: string;
	sex: string;
	mobileNo: string;
	email: string;
}

export interface INHSAdditionalRequests {
	request?: string;
}

export interface CustomObject {
	[key: string]: {
		[key: string]: string | string[];
	};
}

export interface IThingsYouEnjoyInLife {
	selections: string[];
	selectionsDetail: {
		[key: string]: string | string[] | CustomObject;
	};
}

export interface IHealthConcerns {
	isConcerned: HasHealthConcernValues;
	concerns: { concernType: THealthConcernStatement; description?: string }[];
}

export interface IPersonalExperiences {
	hasExperiences: HasExperienceValues;
	statement?: string;
}

export interface IActivities {
	activities: { activityType: TActivities; values: IActivityValues }[];
	ranking: string[];
}

export interface IActivityValues {
	types?: string | string[];
	description?: string;
	activityDescription?: string;
}

export interface ISourcesOfSupport {
	sources: TSourcesOfSupport[];
	description?: string;
	otherSources: {
		[Key: string]: {
			// key
			OTHERS: string;
			// value inside the text area
			OTHER_SOURCES: string;
		};
	};
}

export interface IFearItems {
	fearType: TFears;
	othersType?: string;
	description?: string;
	id?: string;
	isSelectedOther?: boolean;
}

export interface IFears {
	fears: IFearItems[];
	ranking: ITopFearCheckBox[];
}

export interface IGoalsOfCare {
	goalsOfCare: TGoalsOfCare;
	description?: string;
	outcomesToAvoid?: string;
}

interface CareOtherSelectionDetailItem {
	CARE_OTHERS: string;
	CARE_OTHERS_ACTIVITY: string;
	CARE_OTHERS_IMPORTANCES: string;
}
export interface ICarePreferences {
	selections: string[];
	selectionsDetail: {
		[key: string]:
			| string
			| string[]
			| {
					[key: string]: CareOtherSelectionDetailItem;
			  };
	};
}

export interface INHS {
	spokesPersons: INhsDetails[];
}

export interface INhsDetails {
	name: string;
	dob: string;
	mobile: string;
	email: string;
	relationship: string;
	otherRelationship?: string;
}

export interface IAcpState {
	draftSaving: boolean;
	isLoading: boolean;
	isRenderingForm: boolean;
	currentStep: number;
	latestStep: number;
	showDevMenu: boolean;
	personalDetails?: IPersonalDetails;
	nhsAdditionalRequests?: INHSAdditionalRequests;
	thingsYouEnjoyInLife?: IThingsYouEnjoyInLife;
	healthConcerns?: IHealthConcerns;
	personalExperiences?: IPersonalExperiences;
	activities?: IActivities;
	fears?: IFears;
	sourcesOfSupport?: ISourcesOfSupport;
	goalsOfCare?: IGoalsOfCare;
	carePreferences?: ICarePreferences;
	nhs?: INHS;
	// to show last saved time to the user
	draftLastSavedTime?: ZonedDateTime;
	// to manage retriggering of save as draft, if call is success we will set this to true
	lastSaveDraftSuccess?: boolean;
	suitabilityCheck: boolean;
}

export interface IAcpDraftState {
	currentStep: number;
	latestStep: number;
	suitabilityCheck: boolean;
	healthConcerns?: IHealthConcerns;
	thingsYouEnjoyInLife?: IThingsYouEnjoyInLife;
	personalDetails?: IPersonalDetails;
	personalExperiences?: IPersonalExperiences;
	goalsOfCare?: IGoalsOfCare;
	carePreferences?: ICarePreferences;
	sourcesOfSupport?: ISourcesOfSupport;
	nhs?: INHS;
	nhsAdditionalRequests?: INHSAdditionalRequests;
	fears?: IFears;
}

export enum AcpActionType {
	ENABLE_DRAFT_SAVE = "ENABLE_DRAFT_SAVE",
	RESET_FORM = "RESET_FORM",
	SET_LOADING_STATE = "SET_LOADING_STATE",
	SET_RENDERING_FORM = "SET_RENDERING_FORM",
	SHOW_DEV_MENU = "SHOW_DEV_MENU",
	SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS",
	SET_THINGS_YOU_ENJOY_IN_LIFE = "SET_THINGS_YOU_ENJOY_IN_LIFE",
	SET_HEALTH_CONCERNS = "SET_HEALTH_CONCERNS",
	SET_PERSONAL_EXPERIENCES = "SET_PERSONAL_EXPERIENCES",
	SET_ACTIVITIES = "SET_ACTIVITIES",
	SET_SOURCES_OF_SUPPORT = "SET_SOURCES_OF_SUPPORT",
	SET_FEARS = "SET_FEARS",
	SET_GOALS_OF_CARE = "SET_GOALS_OF_CARE",
	SET_CARE_PREFERENCES = "SET_CARE_PREFERENCES",
	SET_NHS = "SET_NHS",
	SET_NHS_ADDITIONAL_REQUESTS = "SET_NHS_ADDITIONAL_REQUESTS",
	SET_REVIEW = "SET_REVIEW",
	SET_FORM_DATA = "SET_FORM_DATA",
	HAS_SAVE_DRAFT_DATA = "HAS_SAVE_DRAFT_DATA",
	SET_DRAFT_DATA = "SET_DRAFT_DATA",
	SET_LATEST_STEP = "SET_LATEST_STEP",
}

interface ToggleDraftSaveAction {
	type: AcpActionType.ENABLE_DRAFT_SAVE;
	payload: boolean;
}

interface ResetFormAction {
	type: AcpActionType.RESET_FORM;
}

interface SetLoadingAction {
	type: AcpActionType.SET_LOADING_STATE;
	payload: boolean;
}

interface SetRenderingAction {
	type: AcpActionType.SET_RENDERING_FORM;
	payload: boolean;
}

interface ShowDevMenuAction {
	type: AcpActionType.SHOW_DEV_MENU;
	payload: boolean;
}

interface SetPersonalDetailsAction {
	type: AcpActionType.SET_PERSONAL_DETAILS;
	payload: IPersonalDetails;
}

interface SetNHSAdditionalRequestAction {
	type: AcpActionType.SET_NHS_ADDITIONAL_REQUESTS;
	payload: INHSAdditionalRequests;
}

interface SetThingsYouEnjoyInLife {
	type: AcpActionType.SET_THINGS_YOU_ENJOY_IN_LIFE;
	payload: IThingsYouEnjoyInLife;
}

interface SetHealthConcerns {
	type: AcpActionType.SET_HEALTH_CONCERNS;
	payload: IHealthConcerns;
}

interface SetPersonalExperiences {
	type: AcpActionType.SET_PERSONAL_EXPERIENCES;
	payload: IPersonalExperiences;
}

interface SetActivities {
	type: AcpActionType.SET_ACTIVITIES;
	payload: IActivities;
}

interface SetSourcesOfSupport {
	type: AcpActionType.SET_SOURCES_OF_SUPPORT;
	payload: ISourcesOfSupport;
}

interface SetFears {
	type: AcpActionType.SET_FEARS;
	payload: IFears;
}

interface SetGoalsOfCare {
	type: AcpActionType.SET_GOALS_OF_CARE;
	payload: IGoalsOfCare;
}

interface SetCarePreferences {
	type: AcpActionType.SET_CARE_PREFERENCES;
	payload: ICarePreferences;
}

interface SetNHS {
	type: AcpActionType.SET_NHS;
	payload: INHS;
}

interface SetFormDataAction {
	type: AcpActionType.SET_FORM_DATA;
	payload: IAcpState;
}

interface SetDraftDataAction {
	type: AcpActionType.SET_DRAFT_DATA;
	payload: IAcpDraftState;
}

interface SetLatestStepAction {
	type: AcpActionType.SET_LATEST_STEP;
	payload: number;
}

interface HasSaveDraftDataAction {
	type: AcpActionType.HAS_SAVE_DRAFT_DATA;
	payload: boolean;
}

interface SetReview {
	type: AcpActionType.SET_REVIEW;
}

export type AcpAction =
	| ToggleDraftSaveAction
	| ResetFormAction
	| SetLoadingAction
	| SetRenderingAction
	| ShowDevMenuAction
	| SetPersonalDetailsAction
	| SetHealthConcerns
	| SetNHSAdditionalRequestAction
	| SetPersonalExperiences
	| SetActivities
	| SetSourcesOfSupport
	| SetFears
	| SetGoalsOfCare
	| SetCarePreferences
	| SetReview
	| SetNHS
	| SetFormDataAction
	| SetDraftDataAction
	| SetLatestStepAction
	| SetThingsYouEnjoyInLife
	| HasSaveDraftDataAction;

export interface IAcpContextType {
	state: IAcpState;
	dispatch: React.Dispatch<AcpAction>;
}
