import { RelationshipToDonee, RelationshipCode } from "app/common/api/lpaAcp";
import { DoneeDO, DoneePowerDO } from "app/common/api/lpaAcp/domainObjects";
import { EMAIL_MAX_CHARS } from "app/common/email";
import { validateLocalPhoneNumber } from "app/common/validators";
import Modal from "app/components/basic/Modal";
import { CombinedFormKeys, DoneePowerLabel, RelationshipLabel } from "app/components/templates/LPA-ACP/common/data";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { useCallback, useEffect, useState } from "react";
import { IAppointPersonModal, isUniquePerson, isUser } from "../common/utils";

const { ID, NAME, NRIC, PHONE_NUMBER, EMAIL_ADDRESS, RELATIONSHIP_CODE, POWERS_GRANTED } = CombinedFormKeys;

const fields: IFormSchema = {
	[ID]: {
		type: "number",
	},
	[NAME]: {
		type: "text",
		maxChar: 100,
		constraints: [
			["isRequired", "Enter Donee’s full name."],
			["isMinLength", 3, "Enter Donee’s full name."],
		],
	},
	[NRIC]: {
		type: "text",
		maxChar: 9,
		constraints: [
			["isRequired", "Enter Donee’s NRIC or FIN number."],
			["isNRIC", "Invalid NRIC or FIN number. Try again."],
		],
	},
	[RELATIONSHIP_CODE]: {
		type: "dropdown",
		dropdownItems: Object.values(RelationshipToDonee).map((value) => {
			return {
				label: RelationshipLabel[value],
				value: Number(RelationshipCode[value]),
			};
		}),
		constraints: [["isRequired", "Select Donee’s relationship to you."]],
	},
	[PHONE_NUMBER]: {
		type: "phone",
		maxChar: 9,
		constraints: [
			["isMobileNumber", "Invalid local mobile number. Enter a Singapore mobile number that begins with 8 or 9."],
			["isRequired", "Enter Donee’s local mobile number."],
		],
	},
	[EMAIL_ADDRESS]: {
		type: "email",
		maxChar: EMAIL_MAX_CHARS,
		constraints: [["isOPGOEmail", "Invalid email address."]],
	},
	[POWERS_GRANTED]: {
		type: "radio",
		radioBoxItems: [
			{ label: "Personal Welfare", value: "1" },
			{ label: "Property and Affairs", value: "2" },
			{ label: "Both Personal Welfare and Property and Affairs", value: "3" },
		],
		constraints: [["isRequired", "Select the decision powers you wish to give your Donee."]],
	},
};

const NewAppointedPersonModalV2 = ({
	donees,
	personList,
	personIndex,
	closeCallback,
	updatePersonList,
	userNric,
}: IAppointPersonModal<DoneeDO>): JSX.Element => {
	const { form } = useForm();
	const { setupFormFields, loading, warnChangesLostIfProceed, getValue, updateFieldError, focusFirstError } = form;
	const [doneeName, setDoneeName] = useState("this Donee");

	const initFormModal = useCallback((): void => {
		if (undefined === personIndex) {
			return setupFormFields(fields, {}, NAME);
		}

		const donee: DoneeDO = personList[personIndex];
		const power: DoneePowerDO = donee.powers[0];

		let powersGranted = DoneePowerLabel.BOTH_POWER_LABEl;
		if (!power.authorizePersonalWelfare) {
			powersGranted = DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL;
		} else if (!power.authorizePropertyAffairs) {
			powersGranted = DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL;
		}

		const initialData = {
			[ID]: donee.id,
			[NAME]: donee.name,
			[NRIC]: donee.nric,
			[RELATIONSHIP_CODE]: donee.relationshipCode,
			[PHONE_NUMBER]: donee.contact,
			[EMAIL_ADDRESS]: donee.email,
			[POWERS_GRANTED]: powersGranted,
		};

		setupFormFields(fields, initialData, NAME);
	}, [personIndex, personList, setupFormFields]);

	const closeModal = useCallback((): void => {
		warnChangesLostIfProceed(() => closeCallback());
	}, [warnChangesLostIfProceed, closeCallback]);

	const handleUpdatePersonList = useCallback(async (): Promise<void> => {
		const powersGranted = getValue(POWERS_GRANTED) as string;
		const power = new DoneePowerDO();
		power.id = -1;
		if (personIndex !== undefined) {
			const currPersonPowers = personList[personIndex].powers[0];
			power.id = currPersonPowers.id < 0 ? currPersonPowers.id : -1;
			Object.assign(power, currPersonPowers);
		}
		power.authorizePersonalWelfare = true;
		power.authorizePropertyAffairs = true;
		if (powersGranted === DoneePowerLabel.AUTHORIZE_PERSONAL_WELFARE_LABEL) {
			power.authorizePropertyAffairs = false;
		} else if (powersGranted === DoneePowerLabel.AUTHORIZE_PROPERTY_AFFAIRS_LABEL) {
			power.authorizePersonalWelfare = false;
		}

		const doneeData = {
			id: (getValue(ID) || -1) as number,
			name: getValue(NAME) as string,
			nric: getValue(NRIC) as string,
			relationshipCode: getValue(RELATIONSHIP_CODE) as number,
			contact: getValue(PHONE_NUMBER) as string,
			email: getValue(EMAIL_ADDRESS) as string,
			powers: [power],
			isCombinedFormSpokesperson: false,
		};

		const newOrUpdatedPerson: DoneeDO = Object.assign(new DoneeDO(), doneeData);

		const updatedList: DoneeDO[] = personList;
		if (undefined !== personIndex) {
			updatedList[personIndex] = newOrUpdatedPerson; // for editing
		} else {
			updatedList.push(newOrUpdatedPerson); // for new
		}

		updatePersonList(updatedList);
		closeCallback();
	}, [closeCallback, getValue, personIndex, personList, updatePersonList]);

	const setFormError = useCallback(
		(type: "duplicate" | "isUserNric") => {
			let errorMsg: string;
			switch (type) {
				case "duplicate":
					errorMsg = "You’ve already added this person as your Donee";
					break;
				case "isUserNric":
					errorMsg = "You cannot appoint yourself as Donee";
					break;
			}

			updateFieldError("nric", errorMsg);
			focusFirstError();
		},
		[focusFirstError, updateFieldError],
	);

	const validateUniqueDonee = useCallback(async () => {
		/* async to fit type of additionalValidations */
		const newNric = getValue(NRIC) as string;
		if (undefined !== personIndex) {
			const listWithoutCurr = [...personList];
			listWithoutCurr.splice(personIndex, 1);

			if (!isUniquePerson(newNric, listWithoutCurr)) {
				setFormError("duplicate");
				return false;
			}

			if (userNric && isUser(newNric, userNric)) {
				setFormError("isUserNric");
				return false;
			}
		} else {
			if (!isUniquePerson(newNric, personList)) {
				setFormError("duplicate");
				return false;
			}

			if (userNric && isUser(newNric, userNric)) {
				setFormError("isUserNric");
				return false;
			}
		}
		return true;
	}, [setFormError, getValue, personIndex, personList, userNric]);

	// get the name of donee
	const getDoneeName = useCallback(() => {
		const validatePhoneNumber =
			!!(getValue(PHONE_NUMBER) as string) && validateLocalPhoneNumber(getValue(PHONE_NUMBER) as string);

		let name = "this Donee";

		if (getValue(NAME) && validatePhoneNumber) {
			name = getValue(NAME);
		}

		setDoneeName(name);
	}, [getValue]);

	useEffect(() => initFormModal(), [initFormModal]);
	useEffect(() => {
		if (!loading) {
			getDoneeName();
		}
	}, [loading, getDoneeName]);

	return (
		<Modal
			id="modal__add-person"
			form={form}
			type="form"
			title="Add Donee"
			isOpen={true}
			closeCallback={closeModal}
			button1={["Cancel", "secondary", closeModal]}
			button2={["Add Donee", "primary", handleUpdatePersonList, undefined, validateUniqueDonee]}
		>
			<div className="add-appointed-person">
				<Form.Input
					field={NAME}
					title="Full name (as in NRIC or FIN)"
					form={form}
					onBlurHandler={getDoneeName}
				/>
				<Form.Input
					field={NRIC}
					title="NRIC or FIN"
					form={form}
					additionalValidations={validateUniqueDonee}
					transformUppercase={true}
					computerWidth={6}
					mobileWidth={12}
				/>
				<Form.Dropdown
					field={RELATIONSHIP_CODE}
					title="Relationship to you"
					form={form}
					computerWidth={6}
					mobileWidth={12}
				/>
				<Form.PhoneNumber
					field={PHONE_NUMBER}
					title="Local mobile number"
					subtitle={
						<>
							The person will receive a text message (SMS) that you chose them as your Donee. They need to
							login to OPGO to accept or reject it.
							<br />
							If they don’t have a local number, use yours instead. Please inform them about what they
							need to do.
						</>
					}
					form={form}
					onBlurHandler={getDoneeName}
					computerWidth={6}
					mobileWidth={12}
				/>
				<Form.Input field={EMAIL_ADDRESS} title="Email address (optional)" form={form} />
				<Form.RadioBox
					title={`I authorise ${doneeName} to decide on my:`}
					field={POWERS_GRANTED}
					columns={1}
					form={form}
				/>
			</div>
		</Modal>
	);
};

export default NewAppointedPersonModalV2;
