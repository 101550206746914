import NricInput from "app/components/basic/NricInput";
import styled from "styled-components";

export const NRIC = styled(NricInput)`
	max-width: 100%;
`;

export const ErrorMessage = styled.p`
	color: #cb2213;
	font-size: 0.875rem;
	font-weight: 600;
	margin: 0.5rem 0 0 !important;
`;
