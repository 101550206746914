import {
	CrossIcon,
	ExclamationCircleFillIcon,
	ExclamationTriangleFillIcon,
	ICircleFillIcon,
	TickCircleFillIcon,
} from "@lifesg/react-icons";
import * as React from "react";
import { ToastType } from "../Toast/Toast";
import "./Snackbar.scss";

interface IProps {
	message: string;
	description?: string;
	type: ToastType | "failure";
	isInline?: boolean;
	timeout?: number | "never";
}

interface IState {
	show: boolean;
}

class Snackbar extends React.Component<IProps, IState> {
	public state: IState = {
		show: true,
	};

	public componentDidMount = (): void => {
		const { isInline, timeout } = this.props;
		if (!isInline && timeout !== "never") {
			// timeout fallbacks to 5000, as that was the fixed value set here before timeout props was introduced
			setTimeout(() => this.setState({ show: false }), timeout ?? 4000);
		}
	};

	public render = (): JSX.Element => {
		const { type, message, description, isInline } = this.props;
		const toastType = type === "failure" ? "error" : type;

		const snackbarClass = isInline ? "snackbar-inline" : "snackbar";
		if (!this.state.show) {
			return <></>;
		}

		return (
			<div className={`${snackbarClass} snackbar__${toastType}`}>
				<div>
					{this.renderIcon(toastType)}
					<span className="snackbar__content-message">{description ? <h5>{message}</h5> : message}</span>
					{isInline !== true && (
						<div
							data-testid={`${toastType}_snackbar_close`}
							className="snackbar__close"
							onClick={this.closeMessageCallback}
						>
							<div className={`close-icon`}>
								<CrossIcon className="ds-icon--size-18" />
							</div>
						</div>
					)}
				</div>
				{description && (
					<span className="snackbar__content-description">
						<p>{description}</p>
					</span>
				)}
			</div>
		);
	};

	private renderIcon = (type: ToastType): JSX.Element => {
		switch (type) {
			case "success":
				return (
					<TickCircleFillIcon className={`snackbar__icon icon__${type} ds-icon--${type} ds-icon--size-24`} />
				);
			case "warning":
				return (
					<ExclamationTriangleFillIcon
						className={`snackbar__icon icon__${type} ds-icon--${type} ds-icon--size-24`}
					/>
				);
			case "info":
				return <ICircleFillIcon className={`snackbar__icon icon__${type} ds-icon--${type} ds-icon--size-24`} />;
			case "error":
				return (
					<ExclamationCircleFillIcon
						className={`snackbar__icon icon__${type} ds-icon--${type} ds-icon--size-24`}
					/>
				);
		}
	};

	private closeMessageCallback = (): void => {
		this.setState({ show: false });
	};
}

export default Snackbar;
