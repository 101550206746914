export const LogoMyLegacySimple = (): JSX.Element => (
	<svg width="305" height="40" viewBox="0 0 305 40" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M40.474 20.8741L21.7677 3.42309C20.7319 2.45889 19.1567 2.46844 18.245 3.45173L4.05889 18.6593C3.1472 19.6378 3.24266 21.213 4.27846 22.1772L22.4455 39.0793C23.7772 40.3203 25.8154 40.306 26.9944 39.0459L40.6984 24.3968C41.6101 23.4182 41.5098 21.8431 40.474 20.8789V20.8741ZM10.9897 23.7858C10.9228 23.8478 10.8226 23.8478 10.7605 23.7858L7.96341 21.1653C7.90136 21.0984 7.90136 20.9982 7.96341 20.9314L10.4741 18.2536C10.541 18.1915 10.6412 18.1915 10.7033 18.2536L13.5004 20.8693C13.5624 20.9361 13.5624 21.0364 13.5004 21.1032L10.9897 23.7858ZM14.8751 27.5519L13.5911 26.3442C13.4861 26.244 13.4765 26.0769 13.5768 25.9672L22.1066 16.8598C22.2068 16.7548 22.3739 16.7452 22.4836 16.8455L23.7676 18.0483C23.8727 18.1486 23.8822 18.3156 23.782 18.4254L15.2426 27.5328C15.1424 27.6378 14.9801 27.6426 14.8751 27.5471V27.5519ZM23.9156 25.3514L18.7748 30.8406C18.6746 30.9457 18.5075 30.9552 18.3977 30.855L17.1137 29.6521C17.0087 29.5519 16.9992 29.3848 17.0994 29.275L22.245 23.7858C22.3452 23.6808 22.5123 23.6712 22.6173 23.7715L23.9061 24.9743C24.0111 25.0746 24.0159 25.2416 23.9156 25.3514Z"
			fill="#24588D"
		/>
		<path
			d="M33.0711 0.00962017C30.5174 0.0907654 28.0974 1.17429 26.3361 3.02154L24.002 5.50841L33.5866 14.4487C32.0306 17.0835 32.4983 20.4487 34.7227 22.5537H34.7322L39.1761 17.8091L40.4983 16.401C44.1737 12.4726 43.9732 6.31031 40.0448 2.63014C38.1594 0.864032 35.6487 -0.0762983 33.0711 0.00484692V0.00962017Z"
			fill="#FFC166"
		/>
		<path
			d="M17.7629 3.95791L16.3261 2.61185C12.3643 -1.02536 6.20204 -0.762836 2.56483 3.19896C-1.01989 7.10348 -0.824183 13.1655 3.00874 16.8266L4.4789 18.2061L13.319 8.72639C15.9442 11.1846 20.0636 11.051 22.5266 8.42567L17.7629 3.95791Z"
			fill="#FFC166"
		/>
		<path
			d="M53.1365 11.7254C53.1365 10.4414 54.1532 9.42469 55.4372 9.42469H55.9432C56.9312 9.42469 57.5852 9.93066 58.0339 10.618L63.5899 19.6681L69.1746 10.5846C69.6519 9.80656 70.3393 9.41992 71.2367 9.41992H71.7426C73.0266 9.41992 74.0433 10.4366 74.0433 11.7206V28.3363C74.0433 29.6203 73.0266 30.637 71.7426 30.637C70.4586 30.637 69.4706 29.6203 69.4706 28.3363V17.9115L65.4372 24.1263C64.9598 24.8423 64.3632 25.291 63.5231 25.291C62.683 25.291 62.0911 24.8423 61.609 24.1263L57.6377 18.0022V28.3984C57.6377 29.6824 56.6496 30.637 55.3656 30.637C54.0816 30.637 53.127 29.6824 53.127 28.3984V11.7254H53.1365Z"
			fill="#24588D"
		/>
		<path
			d="M83.9296 22.3897L77.1754 13.2155C76.8461 12.7668 76.6074 12.2609 76.6074 11.5735C76.6074 10.3468 77.6241 9.36349 78.9081 9.36349C79.9535 9.36349 80.6122 9.93151 81.1802 10.7668L86.2589 18.0842L91.4284 10.7048C91.9964 9.86946 92.6837 9.33008 93.6384 9.33008C95.0417 9.33008 95.877 10.2847 95.877 11.5687C95.877 12.194 95.6097 12.7334 95.2804 13.1821L88.5262 22.2942V28.3276C88.5262 29.6116 87.5095 30.6283 86.2255 30.6283C84.9415 30.6283 83.9248 29.6116 83.9248 28.3276V22.3801L83.9296 22.3897Z"
			fill="#24588D"
		/>
		<path
			d="M104.812 11.664C104.812 10.38 105.829 9.36328 107.113 9.36328C108.397 9.36328 109.414 10.38 109.414 11.664V26.2749H117.929C119.094 26.2749 120.02 27.2009 120.02 28.3656C120.02 29.5303 119.094 30.4563 117.929 30.4563H107.113C105.829 30.4563 104.812 29.4396 104.812 28.1556V11.664Z"
			fill="#24588D"
		/>
		<path
			d="M125.457 30.4565C124.173 30.4565 123.156 29.4398 123.156 28.1558V11.8456C123.156 10.5616 124.173 9.54492 125.457 9.54492H137.051C138.216 9.54492 139.113 10.4423 139.113 11.607C139.113 12.7716 138.216 13.6404 137.051 13.6404H127.729V17.8838H135.705C136.87 17.8838 137.767 18.7812 137.767 19.9458C137.767 21.1105 136.87 21.9792 135.705 21.9792H127.729V26.3706H137.199C138.364 26.3706 139.261 27.268 139.261 28.4327C139.261 29.5973 138.364 30.4661 137.199 30.4661H125.457V30.4565Z"
			fill="#24588D"
		/>
		<path
			d="M141.412 20.0599V19.9978C141.412 14.0504 146.042 9.18164 152.376 9.18164C155.574 9.18164 157.603 9.89763 159.517 11.215C159.966 11.5444 160.472 12.1411 160.472 13.0671C160.472 14.3511 159.484 15.3391 158.2 15.3391C157.512 15.3391 157.126 15.1005 156.796 14.8618C155.512 13.9358 154.228 13.4298 152.224 13.4298C148.877 13.4298 146.219 16.3892 146.219 19.9453V20.0026C146.219 23.826 148.849 26.6374 152.553 26.6374C154.257 26.6374 155.718 26.2174 156.887 25.3821V22.394H153.78C152.672 22.394 151.78 21.53 151.78 20.4226C151.78 19.3153 152.677 18.4227 153.78 18.4227H159.04C160.324 18.4227 161.34 19.4394 161.34 20.7234V25.8021C161.34 27.1147 160.801 28.1028 159.727 28.7281C158.023 29.7734 155.546 30.8188 152.41 30.8188C145.899 30.8188 141.417 26.2794 141.417 20.0647L141.412 20.0599Z"
			fill="#24588D"
		/>
		<path
			d="M164.421 27.5012L171.709 11.0382C172.215 9.87355 173.113 9.21484 174.397 9.21484H174.664C175.948 9.21484 176.817 9.87355 177.323 11.0382L184.611 27.5012C184.759 27.8592 184.85 28.1599 184.85 28.4272C184.85 29.6539 183.895 30.6372 182.669 30.6372C181.595 30.6372 180.907 30.0119 180.459 29.0525L179.055 25.7685H169.852L168.387 29.2052C168 30.1312 167.222 30.6372 166.268 30.6372C165.074 30.6372 164.148 29.6825 164.148 28.4845C164.148 28.1551 164.268 27.8257 164.416 27.4964L164.421 27.5012ZM177.356 21.7064L174.459 14.8043L171.561 21.7064H177.356Z"
			fill="#24588D"
		/>
		<path
			d="M185.928 20.0599V19.9978C185.928 14.0504 190.41 9.18164 196.835 9.18164C199.971 9.18164 202.061 10.017 203.827 11.301C204.305 11.659 204.725 12.3177 204.725 13.1243C204.725 14.4084 203.737 15.363 202.453 15.363C201.827 15.363 201.407 15.1243 201.078 14.9143C199.794 13.9597 198.477 13.4203 196.806 13.4203C193.279 13.4203 190.739 16.3463 190.739 19.9358V19.9931C190.739 23.5778 193.221 26.5658 196.806 26.5658C198.749 26.5658 200.033 25.9692 201.345 24.9238C201.732 24.5945 202.214 24.4179 202.72 24.4179C203.885 24.4179 204.873 25.3725 204.873 26.5372C204.873 27.2532 204.543 27.8212 204.095 28.1792C202.214 29.8212 199.999 30.8092 196.658 30.8092C190.505 30.8092 185.933 26.0599 185.933 20.0551L185.928 20.0599Z"
			fill="#24588D"
		/>
		<path
			d="M214.172 22.3897L207.418 13.2155C207.088 12.7668 206.85 12.2609 206.85 11.5735C206.85 10.3468 207.866 9.36349 209.15 9.36349C210.196 9.36349 210.854 9.93151 211.422 10.7668L216.501 18.0842L221.671 10.7048C222.239 9.86946 222.926 9.33008 223.881 9.33008C225.284 9.33008 226.119 10.2847 226.119 11.5687C226.119 12.194 225.852 12.7334 225.523 13.1821L218.768 22.2942V28.3276C218.768 29.6116 217.752 30.6283 216.468 30.6283C215.184 30.6283 214.167 29.6116 214.167 28.3276V22.3801L214.172 22.3897Z"
			fill="#24588D"
		/>
		<path
			d="M245.709 18.6266C245.709 17.8104 246.353 17.166 247.17 17.166C247.986 17.166 248.63 17.8104 248.63 18.6266V27.8963H254.034C254.773 27.8963 255.361 28.4834 255.361 29.2232C255.361 29.9631 254.773 30.5502 254.034 30.5502H247.174C246.358 30.5502 245.714 29.9058 245.714 29.0896V18.6266H245.709Z"
			fill="#414042"
		/>
		<path
			d="M262.601 22.7896H262.486C261.823 22.7896 261.293 22.2789 261.293 21.6154C261.293 20.9519 261.823 20.4221 262.486 20.4221H262.601V19.7777C262.601 18.6608 262.887 17.8445 263.417 17.3147C263.947 16.7849 264.725 16.5176 265.746 16.5176C266.2 16.5176 266.601 16.5367 266.921 16.594C267.412 16.6703 267.904 17.1047 267.904 17.7491C267.904 18.3935 267.374 18.9424 266.73 18.9424H266.481C265.818 18.9424 265.441 19.2813 265.441 20.0402V20.4364H266.692C267.355 20.4364 267.885 20.9472 267.885 21.6106C267.885 22.2741 267.355 22.7849 266.692 22.7849H265.479V29.1524C265.479 29.9495 264.835 30.5939 264.038 30.5939C263.24 30.5939 262.596 29.9495 262.596 29.1524V22.7849L262.601 22.7896Z"
			fill="#414042"
		/>
		<path
			d="M275.694 24.6082C275.522 23.3194 274.763 22.4459 273.551 22.4459C272.338 22.4459 271.579 23.3003 271.35 24.6082H275.689H275.694ZM273.837 30.7084C270.787 30.7084 268.529 28.5652 268.529 25.4578V25.4196C268.529 22.5223 270.596 20.1309 273.551 20.1309C276.945 20.1309 278.439 22.9184 278.439 25.0187C278.439 25.854 277.851 26.4029 277.093 26.4029H271.388C271.675 27.7108 272.582 28.3934 273.871 28.3934C274.668 28.3934 275.331 28.1451 275.918 27.7108C276.128 27.558 276.314 27.4817 276.62 27.4817C277.264 27.4817 277.737 27.9733 277.737 28.6177C277.737 28.9948 277.565 29.2812 277.36 29.4721C276.467 30.2311 275.312 30.7036 273.832 30.7036"
			fill="#414042"
		/>
		<path
			d="M259.766 21.5589C259.718 20.8048 259.093 20.2129 258.329 20.2129C257.566 20.2129 256.94 20.8095 256.893 21.5589C256.893 21.5924 256.893 21.621 256.893 21.6544V25.5637V29.158C256.893 29.3012 256.912 29.4396 256.955 29.5685C257.103 30.0744 257.523 30.4611 258.043 30.5708C258.138 30.5899 258.234 30.5995 258.334 30.5995C258.434 30.5995 258.53 30.5899 258.625 30.5708C259.146 30.4658 259.566 30.0744 259.714 29.5685C259.752 29.4396 259.776 29.3012 259.776 29.158V25.5637V21.6544C259.776 21.621 259.776 21.5924 259.776 21.5589"
			fill="#414042"
		/>
		<path
			d="M259.896 17.5532C259.729 16.9279 259.108 16.5508 258.33 16.5508C257.552 16.5508 256.932 16.9231 256.765 17.5532C256.736 17.6629 256.717 17.7775 256.717 17.8968V17.935C256.717 18.7703 257.418 19.3002 258.325 19.3002C259.232 19.3002 259.939 18.7703 259.939 17.935V17.8968C259.939 17.7727 259.92 17.6582 259.891 17.5532"
			fill="#414042"
		/>
		<path
			d="M280.357 29.0237C280.023 28.7898 279.789 28.3602 279.789 27.8877C279.789 27.1049 280.414 26.4987 281.197 26.4987C281.589 26.4987 281.861 26.6371 282.056 26.7708C283.092 27.5345 284.228 28.0022 285.56 28.0022C286.791 28.0022 287.536 27.5106 287.536 26.7087V26.6705C287.536 25.9068 287.068 25.5154 284.777 24.9283C282.018 24.2218 280.233 23.4581 280.233 20.7374V20.6992C280.233 18.2123 282.228 16.5703 285.03 16.5703C286.696 16.5703 288.142 16.9999 289.374 17.7827C289.708 17.9784 290.037 18.3889 290.037 18.976C290.037 19.7588 289.412 20.365 288.629 20.365C288.333 20.365 288.099 20.2887 287.865 20.1503C286.868 19.5631 285.927 19.229 284.987 19.229C283.832 19.229 283.226 19.7588 283.226 20.4223V20.4605C283.226 21.3627 283.813 21.6538 286.18 22.26C288.958 22.9856 290.529 23.9832 290.529 26.3698V26.408C290.529 29.1287 288.453 30.6562 285.498 30.6562C283.698 30.6562 281.875 30.1073 280.348 29.0094"
			fill="#EF413D"
		/>
		<path
			d="M291.924 23.6577V23.6195C291.924 19.7246 294.96 16.5312 299.108 16.5312C301.184 16.5312 302.554 16.999 303.785 17.863C304.081 18.0778 304.411 18.4883 304.411 19.0754C304.411 19.8964 303.747 20.5646 302.921 20.5646C302.492 20.5646 302.215 20.4071 302 20.2496C301.179 19.6625 300.296 19.3093 299.007 19.3093C296.816 19.3093 295.074 21.2472 295.074 23.5766V23.6148C295.074 26.1207 296.797 27.9584 299.222 27.9584C300.32 27.9584 301.298 27.6863 302.062 27.1374V25.1804H300.024C299.299 25.1804 298.711 24.6124 298.711 23.8868C298.711 23.1613 299.299 22.5742 300.024 22.5742H303.47C304.31 22.5742 304.979 23.2377 304.979 24.0825V27.4095C304.979 28.2925 304.645 28.8987 303.924 29.3283C302.807 30.0157 301.184 30.6983 299.127 30.6983C294.859 30.6983 291.924 27.7054 291.924 23.6529"
			fill="#EF413D"
		/>
		<path
			d="M241.9 23.7637C241.809 20.0406 238.754 17.043 235.012 17.043C231.269 17.043 228.119 20.136 228.119 23.9308C228.119 27.7255 231.212 30.8186 235.012 30.8186C235.37 30.8186 235.656 30.5274 235.656 30.1742C235.656 29.821 235.37 29.5298 235.012 29.5298C231.923 29.5298 229.413 27.0191 229.413 23.9308C229.413 20.8425 231.923 18.3317 235.012 18.3317C238.1 18.3317 240.611 20.8425 240.611 23.9308V24.747C240.611 25.42 240.095 25.9689 239.465 25.9689C238.835 25.9689 238.32 25.42 238.32 24.747V21.2721C238.32 20.9141 238.028 20.6277 237.675 20.6277C237.322 20.6277 237.031 20.9188 237.031 21.2721V21.3198C236.472 20.8854 235.775 20.6277 235.016 20.6277C233.193 20.6277 231.709 22.1122 231.709 23.9355C231.709 25.7589 233.193 27.2434 235.016 27.2434C235.981 27.2434 236.849 26.8234 237.456 26.1647C237.895 26.8281 238.635 27.2673 239.47 27.2673C240.811 27.2673 241.904 26.1408 241.904 24.7518V23.821C241.904 23.821 241.904 23.7876 241.904 23.7685L241.9 23.7637ZM235.012 25.9499C233.9 25.9499 232.997 25.0429 232.997 23.9355C232.997 22.8281 233.904 21.9212 235.012 21.9212C236.119 21.9212 237.026 22.8281 237.026 23.9355C237.026 25.0429 236.119 25.9499 235.012 25.9499Z"
			fill="#414042"
		/>
	</svg>
);
