import { getMyInfo } from "app/common/api/user";
import { IBeneficiary, IBeneficiaryHistoryMap } from "../../pages/Steps/Beneficiaries/data";
import { ISpecificEstate } from "../../pages/Steps/EstateSpecific/data";
import { IExecutor } from "../../pages/Steps/Executor/data";
import { IResiduaryEstate } from "../../pages/Steps/ResiduaryEstate/data";

export interface IExecutors {
	[key: string]: IExecutor;
}

export interface IExecutorPageDetails {
	selectExecutors: string[];
	additionalExecutors: IExecutors;
	hasReplacementExecutor?: string;
}

export interface IEwillState {
	// holds data for personal detail page - step 1
	personalDetails?: getMyInfo.Response;
	// holds data for beneficiaries page - step 2
	beneficiaries?: IBeneficiary[];
	// holds data for estate specific page - step 3
	hasSpecificEstate: string;
	specificEstate?: ISpecificEstate;
	// holds data for executor page - step 5
	executorPageDetails?: IExecutorPageDetails;
	// holds data for everything thing page - step 4
	residuaryEstate?: IResiduaryEstate;
	// called when form is initially rendered for the first time while data is being fetched
	isRenderingForm: boolean;
	// ewills global loading state when async task are being made/called
	isLoading: boolean;
	// enable showing on dev menu if environment indicated in next.config.js
	showDevMenu: boolean;
	// enables retrieval of data from myInfo
	isSingpassFlow?: boolean;
	/**
	 * Beneficiary content management
	 */
	// holds history of added beneficiaries
	beneficiariesHistoryMap: IBeneficiaryHistoryMap;
	// Contains the map of current added beneficiaries - key: nric, value: name
	beneficiariesNricMap: { [nric: string]: string };

	/**
	 *  these variables are used for page 5 content management
	 *  */
	// contain user added executors
	executors: IExecutors;
	// keep a ref on the executorCount to prevent duplication of keys
	executorCount: number;
	// keep nric of executor for rerendering to know which are duplicates
	executorNric: { [key: string]: string };
	replacementExecutor: IExecutor;
	hasReplacementExecutor: string;
	replacementExecutorToTakePlace: string;

	/**
	 * these variables are used for handling redirection and navigation
	 */
	latestStep: number;
	hasRedirected: boolean;
	hasAttemptedToFetchSessionStorage: boolean;
	preFormSteps: number;
}

export enum EWillsActionType {
	SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS",
	SET_BENEFICIARIES = "SET_BENEFICIARIES",
	SET_HAS_SPECIFIC_ESTATE = "SET_HAS_SPECIFIC_ESTATE",
	SET_SPECIFIC_ESTATE = "SET_SPECIFIC_ESTATE",
	SET_RESIDUARY_ESTATE = "SET_RESIDUARY_ESTATE",
	RESET_FORM = "RESET_FORM",
	SET_RENDERING_FORM = "SET_RENDERING_FORM",
	SHOW_DEV_MENU = "SHOW_DEV_MENU",
	SET_EWILLS_LOADING_STATE = "SET_EWILLS_LOADING_STATE",
	SET_IS_SINGPASS_FLOW = "SET_IS_SINGPASS_FLOW",
	// Content management for step 5
	SET_EXECUTOR = "SET_EXECUTOR",
	SET_EXECUTORS = "SET_EXECUTORS",
	SET_HAS_REPLACEMENT_EXECUTOR = "SET_HAS_REPLACEMENT_EXECUTOR",
	SET_REPLACEMENT_EXECUTOR = "SET_REPLACEMENT_EXECUTOR",
	SET_REPLACEMENT_EXECUTOR_TO_TAKE_PLACE = "SET_REPLACEMENT_EXECUTOR_TO_TAKE_PLACE",

	REMOVE_EXECUTORS = "REMOVE_EXECUTORS",
	SET_EXECUTOR_DETAILS = "SET_EXECUTOR_DETAILS",
	SET_EXECUTOR_NRIC = "SET_EXECUTOR_NRIC",
	SET_EWILLS_DATA = "SET_EWILLS_DATA",
	SET_LATEST_STEP = "SET_LATEST_STEP",
	SET_HAS_REDIRECTED = "SET_HAS_REDIRECTED",
	SET_HAS_FETCH_SESSION_STORAGE = "SET_HAS_FETCH_SESSION_STORAGE",
	SET_PRE_FORM_STEP = "SET_PRE_FORM_STEP",
}

// CONTEXT ACTIONS
interface SetPersonalDetailsAction {
	type: EWillsActionType.SET_PERSONAL_DETAILS;
	payload: getMyInfo.Response;
}

interface SetBeneficiariesAction {
	type: EWillsActionType.SET_BENEFICIARIES;
	payload?: IBeneficiary[];
}

interface SetHasSpecificEstateAction {
	type: EWillsActionType.SET_HAS_SPECIFIC_ESTATE;
	payload: string;
}

interface SetSpecificEstateAction {
	type: EWillsActionType.SET_SPECIFIC_ESTATE;
	payload?: ISpecificEstate;
}

interface ResetFormAction {
	type: EWillsActionType.RESET_FORM;
}

interface SetIsSingpassFlow {
	type: EWillsActionType.SET_IS_SINGPASS_FLOW;
	payload: boolean;
}

interface ShowDevMenuAction {
	type: EWillsActionType.SHOW_DEV_MENU;
	payload: boolean;
}

interface SetRenderingAction {
	type: EWillsActionType.SET_RENDERING_FORM;
	payload: boolean;
}

interface SetLoadingAction {
	type: EWillsActionType.SET_EWILLS_LOADING_STATE;
	payload: boolean;
}

interface SetResiduaryEstate {
	type: EWillsActionType.SET_RESIDUARY_ESTATE;
	payload: IResiduaryEstate;
}

interface SetExecutor {
	type: EWillsActionType.SET_EXECUTOR;
	payload: IExecutor;
}

interface RemoveExecutors {
	type: EWillsActionType.REMOVE_EXECUTORS;
	payload: string;
}

interface SetExecutorsDetail {
	type: EWillsActionType.SET_EXECUTOR_DETAILS;
	payload: IExecutorPageDetails;
}

interface SetExecutors {
	type: EWillsActionType.SET_EXECUTORS;
	payload: IExecutors;
}

interface SetExecutorNric {
	type: EWillsActionType.SET_EXECUTOR_NRIC;
	payload: { key: string; nric: string };
}
interface SetReplacementExecutor {
	type: EWillsActionType.SET_REPLACEMENT_EXECUTOR;
	payload: IExecutor;
}
interface SetHasReplacementExecutor {
	type: EWillsActionType.SET_HAS_REPLACEMENT_EXECUTOR;
	payload: string;
}
interface setReplacementExecutorToTakePlace {
	type: EWillsActionType.SET_REPLACEMENT_EXECUTOR_TO_TAKE_PLACE;
	payload: string;
}

interface SetEwillsData {
	type: EWillsActionType.SET_EWILLS_DATA;
	payload: IEwillState;
}
interface SetLatestStep {
	type: EWillsActionType.SET_LATEST_STEP;
	payload: number;
}

interface SetHasRedirected {
	type: EWillsActionType.SET_HAS_REDIRECTED;
	payload: boolean;
}

interface SetPreFormStep {
	type: EWillsActionType.SET_PRE_FORM_STEP;
	payload: number;
}

interface SetHasFetchedSessionStorage {
	type: EWillsActionType.SET_HAS_FETCH_SESSION_STORAGE;
}

export type EWillsAction =
	| SetPersonalDetailsAction
	| SetBeneficiariesAction
	| SetSpecificEstateAction
	| SetResiduaryEstate
	| ResetFormAction
	| SetRenderingAction
	| SetLoadingAction
	| SetHasSpecificEstateAction
	| ShowDevMenuAction
	| SetIsSingpassFlow
	| SetExecutor
	| SetExecutorsDetail
	| SetExecutors
	| SetHasReplacementExecutor
	| setReplacementExecutorToTakePlace
	| SetReplacementExecutor
	| RemoveExecutors
	| SetExecutorNric
	| SetEwillsData
	| SetLatestStep
	| SetHasRedirected
	| SetHasFetchedSessionStorage
	| SetPreFormStep;

export interface IEwillContextType {
	state: IEwillState;
	dispatch: React.Dispatch<EWillsAction>;
}
