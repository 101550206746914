import { Color } from "@lifesg/react-design-system/color";
import { Footer } from "@lifesg/react-design-system/footer";
import { Layout } from "@lifesg/react-design-system/layout";
import { MediaQuery } from "@lifesg/react-design-system/media";
import { Text } from "@lifesg/react-design-system/text";
import styled from "styled-components";

interface FooterImageProps {
	$maxHeight: string;
}

export const DSFooter = styled(Footer)`
	margin-top: auto;
	> section > div {
		margin: 0;
		padding: 0 !important;

		@media screen and (min-width: 1028px) {
			margin-left: 1rem;
		}

		@media screen and (min-width: 1500px) {
			margin: 0 auto;
			min-width: 1440px;
		}
	}

	a:visited {
		color: ${Color.Neutral[1]};
		svg {
			color: ${Color.Neutral[1]} !important;
			vertical-align: sub;
		}
	}

	a:hover {
		color: ${Color.Neutral[3]};
		svg path {
			fill: ${Color.Neutral[3]} !important;
		}
	}
	a {
		svg path {
			fill: ${Color.Neutral[1]};
		}
	}
`;

export const MainSection = styled(Layout.Content)`
	padding: 4rem 0;
	border-bottom: 1px solid ${Color.Neutral[5]};

	a:hover {
		color: ${Color.Secondary};
		svg {
			color: ${Color.Secondary};
		}
	}

	${MediaQuery.MaxWidth.tablet} {
		padding: 2rem 0;
	}
`;

export const LogoSection = styled.div`
	grid-column: 1 / span 2;

	img {
		height: 2.1rem;
		width: auto;
	}

	${MediaQuery.MaxWidth.tablet} {
		grid-column: 1 / span 8;
		margin-bottom: 2rem;
	}

	${MediaQuery.MaxWidth.mobileL} {
		grid-column: 1 / span 4;
		margin-bottom: 2rem;
	}
`;

export const LinksColumn = styled.ul`
	// first col
	grid-column: 3 / span 2;
	list-style-type: none;
	padding: 0;
	margin: 0;

	:nth-of-type(2) {
		// 2nd col
		grid-column: 5 / span 2;
	}

	li {
		:not(:last-child) {
			margin-bottom: 0.5rem;
		}

		a {
			display: inline-block;
			vertical-align: top;
			line-height: 1rem;
		}
	}

	${MediaQuery.MaxWidth.tablet} {
		// first col
		grid-column: 1 / span 4;

		:nth-of-type(2) {
			// 2nd col
			grid-column: 5 / span 4;
		}
	}

	${MediaQuery.MaxWidth.mobileL} {
		// first col
		grid-column: 1 / span 2;

		:nth-of-type(2) {
			// 2nd col
			grid-column: 3 / span 2;
		}
	}
`;

export const Link = styled(Text.Hyperlink.Small)`
	color: ${Color.Neutral[1]};
`;

export const CreditsSection = styled.div`
	grid-column: 9 / span 4;

	${MediaQuery.MaxWidth.tablet} {
		grid-column: 1 / span 8;
		margin-top: 2rem;
	}

	${MediaQuery.MaxWidth.mobileL} {
		grid-column: 1 / span 4;
	}
`;

export const CreditsLogos = styled.div`
	display: flex;
	margin-top: 1rem;
	gap: 2rem;
`;

export const FooterImage = styled.img<FooterImageProps>`
	max-width: 100%;
	height: auto;
	max-height: ${({ $maxHeight }) => $maxHeight};
`;
