import { Color } from "@lifesg/react-design-system/color";
import { Form } from "@lifesg/react-design-system/form";
import { FormMaskedInputProps } from "@lifesg/react-design-system/form/types";
import { BaseTheme } from "@lifesg/react-design-system/theme";
import { EyeSlashIcon } from "@lifesg/react-icons/eye-slash";
import { validateUinfin } from "@wog/mol-lib-api-contract/utils/data/sg-uinfin";
import { IFormInputProps, withBaseInput } from "app/hooks/useForm/components/Form.Input";
import { useEffect, useState } from "react";

interface IMaskedInputProps {
	disableValidation?: boolean;
	index?: string;
}

type IFormMaskedInputProps = Omit<FormMaskedInputProps, "form"> & IFormInputProps & IMaskedInputProps;

const Input = (props: FormMaskedInputProps & { origProps: IFormMaskedInputProps }) => {
	const { maskRange = [1, 4], disableValidation, index } = props.origProps;
	const [disableMask, setDisableMask] = useState(true);
	const [hasFocus, setHasFocus] = useState(false);
	const { onBlur, onFocus, ...restProps } = props;

	useEffect(() => {
		if (!disableValidation) {
			setDisableMask(!validateUinfin(props.value as string));
		} else {
			setDisableMask(false);
		}
	}, [disableValidation, props.value]);

	return (
		<Form.MaskedInput
			{...restProps}
			key={
				props.disabled
					? `nric-input-disabled-${index || props.value}`
					: `nric-input-enabled-${index || props.value}`
			}
			maskRange={disableMask ? undefined : maskRange}
			iconUnmask={disableMask && props.value ? <EyeSlashIcon /> : undefined}
			iconActiveColor={disableMask ? Color.Neutral[4]({ theme: BaseTheme }) : undefined}
			value={props.value}
			autoFocus={hasFocus}
			onFocus={(e) => {
				setHasFocus(true);
				onFocus && onFocus(e);
			}}
			onBlur={(e) => {
				setHasFocus(false);
				onBlur && onBlur(e);
			}}
			transformInput={"uppercase"}
		/>
	);
};

const NricInput = withBaseInput<IFormMaskedInputProps>(Input);

export default NricInput;
