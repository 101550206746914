import { Transform, Type } from "class-transformer";
import { IsBoolean, IsDefined, IsEnum, IsNumber, ValidateNested } from "class-validator";
import moment from "moment";
import { IsMoment } from "../../../validators/momentValidator";
import { toMoment, toNumber } from "../../transformers";
import { AccessType } from "../enums";
import { type SharedAccessDataTypes } from "../types";
import { FullTrustedPersonDO } from "./../../trustedConnection/domainObjects";

export class BaseSharedAccessDO {
	@IsNumber()
	@Transform(toNumber)
	public id!: number;

	@IsMoment()
	@Transform(toMoment)
	public createdAt!: moment.Moment;

	@IsMoment()
	@Transform(toMoment)
	public updatedAt!: moment.Moment;

	@IsEnum(AccessType)
	public type!: AccessType;

	@IsBoolean()
	public isAccessibleBeforeDeath!: boolean;

	@IsMoment()
	@Transform(toMoment)
	public sharedOn!: moment.Moment;
}

export class SharedAccessTrustedConnectionDO extends BaseSharedAccessDO {
	@ValidateNested()
	@Type(() => FullTrustedPersonDO)
	public trustedConnection!: FullTrustedPersonDO;
}

// @CONTRACT - fix down typing for data
export class SharedAccessDataDO extends BaseSharedAccessDO {
	@IsDefined()
	public data!: SharedAccessDataTypes;
}
