import FileInput, { IFileObject } from "app/components/basic/FileInput";
import _ from "lodash";
import { IFormFunctions } from "../Form";

interface IFormFileInputProps {
	field: string;
	form: IFormFunctions;
	fileTypes: string[];
	maximumFileSize?: number;
	maximumFileNumber?: number;
	title?: string;
	information?: string;
	width?: 75 | 100;
}

const FormFileInput = (props: IFormFileInputProps): JSX.Element => {
	const formField = props.form.getField(props.field);
	const key = formField ? formField.key : "";
	let id = props.field + "__loading";

	if (!props.form.loading) {
		id = `${key}_form_file_input`;
	}

	const value = formField ? _.cloneDeep(formField.value) : [];

	const updateSubmitting = (file: IFileObject, updateType: "add" | "remove"): void => {
		const existingStatus = props.form.submittingFields[key] || [];
		if (updateType === "add") {
			existingStatus.push(file);
			props.form.updateSubmittingStatusForField(key, existingStatus);
		} else if (updateType === "remove") {
			const adjustedFiles = existingStatus.filter(
				(submittingFile: IFileObject) => file.displayName !== submittingFile.displayName,
			);
			if (adjustedFiles.length === 0) {
				props.form.updateSubmittingStatusForField(key, false); // no more submitting;
			} else {
				props.form.updateSubmittingStatusForField(key, adjustedFiles);
			}
		}
	};

	const onFileUpload = async (file: IFileObject): Promise<void> => {
		const validatedFile = file;
		const currentFiles: IFileObject[] = formField.value as IFileObject[];
		currentFiles.push(validatedFile);
		updateSubmitting(validatedFile, "add");
		props.form.updateFieldsObject(formField.key, { value: currentFiles });
	};

	const onFileRemove = async (index: number): Promise<void> => {
		const currentFiles: IFileObject[] = formField.value as IFileObject[];
		const removedFiles = currentFiles.splice(index, 1);
		updateSubmitting(removedFiles[0], "remove");
		props.form.updateFieldsObject(formField.key, { value: currentFiles });
	};

	const onFileUploadComplete = async (file: IFileObject, index: number, hasError: boolean): Promise<void> => {
		const currentFiles: IFileObject[] = formField.value as IFileObject[];
		currentFiles[index] = { ...file, hasError };
		updateSubmitting(file, "remove");
		props.form.updateFieldsObject(formField.key, { value: currentFiles });
	};

	return (
		<div className="form-field form form-field__input--file">
			<FileInput
				{...props}
				name={id}
				id={id}
				label={props.title}
				uploadedFiles={value as IFileObject[]}
				onFileUpload={onFileUpload}
				onFileRemove={onFileRemove}
				onFileUploadComplete={onFileUploadComplete}
				information={props.information}
			/>
		</div>
	);
};

export default FormFileInput;
