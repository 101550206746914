import { Color } from "@lifesg/react-design-system/color";
import { Text } from "@lifesg/react-design-system/text";
import styled from "styled-components";
import { ProgressBarColors } from ".";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ProgressBackground = styled.div<{ color: ProgressBarColors }>`
	height: 1rem;
	width: 100%;
	background-color: ${(props) => props.color};
	border-radius: 6.25rem;
	margin-top: 0.5rem;
`;

export const ProgressFill = styled.div<{ percentage: number; color: ProgressBarColors }>`
	height: 100%;
	width: ${(props) => props.percentage}%;
	background-color: ${(props) => props.color};
	border-radius: inherit;
`;

export const Title = styled(Text.H4)`
	margin: 0;
	color: ${Color.Neutral[2]};
`;

export const Subtitle = styled(Text.H6)<{ color: ProgressBarColors }>`
	line-height: 1.5rem;
	margin: 0.5rem 0 0 0;
	color: ${(props) => props.color};
`;

export const LabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const Label = styled.p<{ color: ProgressBarColors }>`
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	color: ${(props) => props.color};
	> span {
		font-weight: 600 !important;
	}
	margin: 0.5rem 0;
`;
