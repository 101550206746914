export enum EOLErrorCodes {
	ValidationError = "ValidationError",
	ValidationStepError = "ValidationStepError",
	UserNotFoundError = "UserNotFoundError",
	FuneralPlanNotFoundError = "FuneralPlanNotFoundError",
	IncorrectFuneralPlanTypeError = "IncorrectFuneralPlanTypeError",
	AppointedPersonNotFoundError = "AppointedPersonNotFoundError",
	MaximumAppointedPersonsError = "MaximumAppointedPersonsError",
	MaximumDoneesError = "MaximumDoneesError",
	AttachmentNotCreatedError = "AttachmentNotCreatedError",
	AttachmentNotFoundError = "AttachmentNotFoundError",
	MaximumAttachmentsError = "MaximumAttachmentsError",
	AttachmentNotDeletedError = "AttachmentNotDeletedError",
	ServerError = "ServerError",
	FileError = "FileError",
	DuplicateTrustedPersonError = "DuplicateTrustedPersonError",
	UnauthorisedError = "UnauthorisedError",
	InvitationsNotFoundError = "InvitationsNotFoundError",
	VerificationError = "VerificationError",
	TrustedConnectionNotFoundError = "TrustedConnectionNotFoundError",
	TrustedConnectionDuplicateNicknameError = "TrustedConnectionDuplicateNicknameError",
	TrustedConnectionStatusPendingError = "TrustedConnectionStatusPendingError",
	FuneralPlanAccessNotFoundError = "FuneralPlanAccessNotFoundError",
	DuplicateSharedAccessError = "DuplicateSharedAccessError",
	SharedAccessNotFoundError = "SharedAccessNotFoundError",
	InvalidTrustedConnectionError = "InvalidTrustedConnectionError",
	SendSMSError = "SendSMSError",
	SendEmailError = "SendEmailError",
	MaximumInvitesError = "MaximumInvitesError",
	InvalidTrustedConnectionStatusError = "InvalidTrustedConnectionStatusError",
	InvalidTrustedPersonNricError = "InvalidTrustedPersonNricError",
	InvalidInvitationStatusError = "InvalidInvitationStatusError",
	APEXQueryError = "APEXQueryError",
	AuthError = "AuthError",
	PersonalInfoNotFoundError = "PersonalInfoNotFoundError",
	AWSS3CopyFileError = "AWSS3CopyFileError",
	AWSS3FileNotFoundError = "AWSS3FileNotFoundError",
	AWSS3FileNotScannedError = "AWSS3FileNotScannedError",
	NetworkError = "NetworkError",
	GetNetworkError = "GetNetworkError",
	UnknownFrontEndError = "UnknownFrontEndError",
	FileScanError = "FileScanError",
	OnboardingError = "OnboardingError",
	AlreadyOnboardedError = "AlreadyOnboardedError",
	OTPNotFoundError = "OTPNotFoundError",
	OTPMismatchError = "OTPMismatchError",
	OTPMaxAttemptsReachedError = "OTPMaxAttemptsReachedError",
	OTPMaxAttemptsForUserReachedError = "OTPMaxAttemptsForUserReachedError",
	OTPExpiredError = "OTPExpiredError",
	OTPCannotBeResentError = "OTPCannotBeResentError",
	OTPTooFrequentError = "OTPTooFrequentError",
	MOLMyInfoError = "MOLMyInfoError",
	LPANotFoundError = "LPANotFoundError",
	DoneeNotFoundError = "DoneeNotFoundError",
	TransformToDraftFormatError = "TransformToDraftFormatError",
	CPFRetrievalNotAllowedError = "CPFRetrievalNotAllowedError",
	ACPNotFoundError = "ACPNotFoundError",
	WillNotFoundError = "WillNotFoundError",
	CPFAPICallError = "CPFAPICallError",
	ICALifeStatusCheckDisabledError = "ICALifeStatusCheckDisabledError",
	ICAApiFailureError = "ICAApiFailureError",
	NotificationNotFoundError = "NotificationNotFoundError",
	PDFGenerationError = "PDFGenerationError",
	MOLMyInfoMissingFieldError = "MOLMyInfoMissingFieldError",
	ShareNotAllowedError = "ShareNotAllowedError",
	WrongTrustedConnectionError = "WrongTrustedConnectionError",
	WrongContactTypeError = "WrongContactTypeError",
	WrongContactError = "WrongContactError",
	InvalidContentTypeError = "InvalidContentTypeError",
	InvalidInvitationIdError = "InvalidInvitationIdError",
	CSPViolationError = "CSPViolationError",
	ConfigCatSetupError = "ConfigCatSetupError",
	ConfigCatRetrievalError = "ConfigCatRetrievalError",
	TownCouncilError = "TownCouncilError",
	ReCAPTCHAError = "ReCAPTCHAError",
	ContentTypeNotAcceptableError = "ContentTypeNotAcceptableError",
	BSGApiCallError = "BSGApiCallError",
	LPAACPNotFoundError = "LPAACPNotFoundError",
	DeathRecordNotFoundError = "DeathRecordNotFoundError",
	DeathRecordNotFoundDuringSubmissionError = "DeathRecordNotFoundDuringSubmissionError",
	DeathRecordExpiredError = "DeathRecordExpiredError",
	DeathRecordDateOfDeathExpiryError = "DeathRecordDateOfDeathExpiryError",
	DeathCertDownloadRequestNotFoundError = "DeathCertDownloadRequestNotFoundError",
	DeathCertDownloadRequestExceededEmailLimitError = "DeathCertDownloadRequestExceededEmailLimitError",
	CsatError = "CsatError",
	OpgoError = "OpgoError",
	AcpError = "AcpError",
	AcpMockError = "AcpMockError",
	AcpEncryptDecryptError = "AcpEncryptDecryptError",
	CCubeDraftError = "CCubeDraftError",
	LpaAcpDraftError = "LpaAcpDraftError",
	DraftTypeError = "DraftTypeError",
	NricNotFoundError = "NricNotFoundError",
	OneMapError = "OneMapError",
	GetListingPageConfigError = "GetListingPageConfigError",
	RateLimitError = "RateLimitError",
	// Csat
	CsatRatingLimitError = "CsatRatingLimitError",
	CsatDataNotFoundError = "CsatDataNotFoundError",
	// ACP 3.0
	AcpDraftDoesNotExistError = "AcpDraftDoesNotExistError",
	AcpCreateDraftError = "AcpCreateDraftError",
	AcpDeleteDraftError = "AcpDeleteDraftError",
	AcpStartOverError = "AcpStartOverError",
	AcpGetStatusError = "AcpGetStatusError",
	AcpStatusFetchError = "AcpStatusFetchError",
	AcpSaveFormError = "AcpSaveFormError",
	// hdb carparking
	HdbParkingRetrievalError = "HdbParkingRetrievalError",
	HdbParkingApplicationError = "HdbParkingApplicationError",
	HdbParkingNotFoundError = "HdbParkingNotFoundError",
	HdbParkingEmailParamsError = "HdbParkingEmailParamsError",
	HdbParkingEmailError = "HdbParkingEmailError",
	HdbParkingSIDError = "HdbParkingSIDError",
	HdbParkingResourceError = "HdbParkingResourceError",
	HdbParkingDeleteSIDError = "HdbParkingDeleteSIDError",
	OSFetchCarParkDetailsError = "OSFetchCarParkDetailsError",
	// SQS errors
	SQSReminderCreationError = "SQSReminderCreationError",
	SQSReminderMissingFieldError = "SQSReminderMissingFieldError",
}

export const EOLErrorMessages: { [key in EOLErrorCodes]: string } = {
	ValidationError: "ValidationError",
	ValidationStepError: "Trying to save an invalid step",
	UserNotFoundError: "User not found.",
	FuneralPlanNotFoundError: "Funeral plan not found.",
	IncorrectFuneralPlanTypeError: "Funeral plan type is incorrect.",
	AppointedPersonNotFoundError: "Appointed person not found.",
	MaximumAppointedPersonsError: "Maximum number of allowed appointed persons exists.",
	MaximumDoneesError: "Maximum number of allowed donees exists.",
	AttachmentNotCreatedError: "Attachment could not be created.",
	AttachmentNotFoundError: "Attachment not found.",
	AttachmentNotDeletedError: "Attachment could not be deleted. Please try again later.",
	MaximumAttachmentsError: "Maximum number of allowed attachments exists.",
	ServerError: "An unexpected error has occurred.",
	FileError: "FileError",
	DuplicateTrustedPersonError: "Trusted Person already exists.",
	UnauthorisedError: "User is not authorised to perform this action.",
	InvitationsNotFoundError: "User has no invitations.",
	VerificationError: "VerificationError",
	TrustedConnectionNotFoundError: "Trusted connection is not found.",
	TrustedConnectionDuplicateNicknameError: "You already have a trusted connection with the same nickname",
	TrustedConnectionStatusPendingError: "Trusted connection is still in pending state",
	InvalidTrustedConnectionError: "Trusted connection has been rejected, has expired or has been revoked.",
	FuneralPlanAccessNotFoundError: "Funeral Plan Access is not found",
	DuplicateSharedAccessError: "Shared access already exists.",
	SharedAccessNotFoundError: "Shared access not found.",
	SendSMSError: "SMS was not sent.",
	SendEmailError: "Email was not sent.",
	MaximumInvitesError: "User has been invited for 5 times already.",
	InvalidTrustedConnectionStatusError: "Invitation cannot be resent. User has not declined the previous invitation.",
	InvalidTrustedPersonNricError: "Please enter the NRIC or FIN of the person you want to invite.",
	APEXQueryError: "The APEX query has failed.",
	AuthError: "Auth Provider returned an error",
	PersonalInfoNotFoundError: "Personal info not found.",
	AWSS3CopyFileError: "File was not copied.",
	AWSS3FileNotFoundError: "File was not found in S3",
	AWSS3FileNotScannedError: "File was not scanned",
	NetworkError: "We could not connect to the network. Please check your connection and try again.",
	GetNetworkError: "For some reason, we can’t load your project. Try refreshing your browser.",
	UnknownFrontEndError: "Unknown front-end error has occurred.",
	FileScanError: "File scan has encountered an error.",
	OnboardingError: "User is not onboarded",
	AlreadyOnboardedError: "User is already onboarded",
	OTPNotFoundError: "OTP is not found",
	OTPMismatchError: "OTP does not match",
	OTPMaxAttemptsReachedError: "OTP has reached max attempt",
	OTPMaxAttemptsForUserReachedError: "You have reached your max attempt",
	OTPExpiredError: "OTP has expired after 3 minutes",
	OTPTooFrequentError: "You have requested for an OTP within a minute prior. Please try again later",
	MOLMyInfoError: "Error retrieving data from Myinfo",
	CPFRetrievalNotAllowedError:
		"We are unable to check your nomination as you did not allow us to retrieve information from CPF.",
	ACPNotFoundError: "ACP not found.",
	WillNotFoundError: "Will not found.",
	CPFAPICallError: "Error when calling cpf api endpoint",
	LPANotFoundError: "LPA not found.",
	DoneeNotFoundError: "Donee not found",
	TransformToDraftFormatError: "Error when transform lpa acp form to draft format",
	ICALifeStatusCheckDisabledError: "ICA life status API disabled",
	ICAApiFailureError: "Error calling ICA Api",
	NotificationNotFoundError: "Notification not found",
	PDFGenerationError: "Unable to generate PDF",
	MOLMyInfoMissingFieldError: "Phone number or email cannot be found from Myinfo",
	ShareNotAllowedError: "You are not authorised to add shares for this plan/personal information",
	WrongTrustedConnectionError: "The invitation cannot be updated. Please check your invitation id",
	InvalidInvitationStatusError: "The invitation cannot be accepted / declined",
	WrongContactTypeError: "You have entered the wrong contact type for verification",
	WrongContactError: "The contact used to request for OTP is wrong.",
	OTPCannotBeResentError: "OTP code cannot be resent. Please check that the OTP has been sent before.",
	InvalidContentTypeError: "This is not a valid content type for file",
	InvalidInvitationIdError: "Invalid input parameters encountered. Please check again.",
	CSPViolationError: "CSP has been violated.",
	ConfigCatSetupError: "ConfigCat cannot be set up properly. Please check if api key exists",
	ConfigCatRetrievalError: "ConfigCat cannot retrieve the toggle value. Please check your toggle key",
	TownCouncilError: "Error getting town council name",
	ReCAPTCHAError: "ReCAPTCHA verification failed",
	ContentTypeNotAcceptableError: "Content-Type not acceptable error",
	BSGApiCallError: "Error calling BSG Api",
	LPAACPNotFoundError: "LPA ACP combined form not found.",
	DeathRecordNotFoundError: "Death-Record not found",
	DeathRecordNotFoundDuringSubmissionError: "Death-Record not found when user submit the form",
	DeathRecordExpiredError: "Death-Record expired",
	DeathRecordDateOfDeathExpiryError: "The application must be submitted within 7 days from the date of death",
	DeathCertDownloadRequestNotFoundError: "Death cert download request not found",
	DeathCertDownloadRequestExceededEmailLimitError: "Death cert download exceeded email limit",
	CsatError: "Error calling Csat Api",
	OpgoError: "Error calling Opgo Api",
	AcpError: "Error calling ACP Api",
	AcpMockError: "Error calling ACP Mock",
	AcpEncryptDecryptError: "Error when encrypt decrypt ACP data",
	CCubeDraftError: "Error calling CCube Draft Api",
	LpaAcpDraftError: "Error calling LpaAcp Draft Api",
	DraftTypeError: "Draft type not found",
	NricNotFoundError: "Something went wrong. Please contact the admin",
	OneMapError: "Error calling OneMap Api",
	GetListingPageConfigError: "Something went wrong when fetching listing page config",
	RateLimitError: "Rate Limit Reached",
	// Csat
	CsatRatingLimitError: "User rated for this CSAT rating!",
	CsatDataNotFoundError: "The reference data is not existed!",
	// ACP 3.0
	AcpDraftDoesNotExistError: "User does not have a existing draft",
	AcpCreateDraftError: "Something went wrong creating a draft",
	AcpDeleteDraftError: "Something went wrong deleting a draft",
	AcpStartOverError: "Something went wrong start over a acp",
	AcpGetStatusError: "Something went wrong when fetching the acp status",
	AcpStatusFetchError:
		"Problem getting the status of your Advance Care Plan. Please refresh the page or try again later.",
	AcpSaveFormError: "Something went wrong when save form",
	// hdb carparking
	HdbParkingRetrievalError: "Something went wrong fetching hdb parking record",
	HdbParkingNotFoundError: "Hdb parking record not found",
	HdbParkingEmailParamsError: "Invalid params received when trying to send email",
	HdbParkingEmailError: "Something went wrong trying to send parking confirmation email to user",
	HdbParkingApplicationError: "Something went wrong trying to create a hdb parking application",
	OSFetchCarParkDetailsError: "Something went wrong when fetching carpark details from OS",
	HdbParkingSIDError: "Something went wrong when creating a SID",
	HdbParkingDeleteSIDError: "Something went wrong when deleting SID",
	HdbParkingResourceError: "Something went wrong when retrieving Hdb parking resource",
	// SQS error
	SQSReminderCreationError: "Something went wrong when creating a reminder",
	SQSReminderMissingFieldError: "Missing necessary field when trying to create a reminder",
};
