import { DateTimeException, LocalDate } from "@js-joda/core";
import { validateUinfin, isForeignerByUinfin } from "@wog/mol-lib-api-contract/utils/data/sg-uinfin";
import { DoneeDO } from "app/common/api/lpaAcp/domainObjects";
import { isUserAgeBelowThreshold } from "app/common/date";
import {
	validateContactNumber,
	validateIdentification,
	validateLocalPhoneNumber,
	validateMobileNumber,
	validateVehicleNumber,
} from "app/common/validators";
import { ICurrencyValue } from "app/components/basic/CurrencyInput";
import { IDateValue } from "app/components/basic/Date";
import { dateToMoment, dateToString } from "app/components/basic/Date/utils";
import { IFileObject } from "app/components/basic/FileInput";
import { AddressSchemaFields } from "app/modules/eWills/pages/Steps/EstateSpecific/components/AddressFields/data";
import { isValidCarplate } from "app/utils/carplate";
import moment from "moment";
import { IFormField } from "./Form";
import { isFloorUnitValid } from "./utils";

export type IInputValidatorNames =
	| "isEmail"
	| "isOPGOEmail"
	| "isRequired"
	| "isNumberString"
	| "isNRIC"
	| "isNRICLocal"
	| "isNRICShape"
	| "isLocalPhoneNumber"
	| "isMobileNumber"
	| "isNot"
	| "isNumber"
	| "isMoney"
	| "isZero"
	| "isMaxValue"
	| "isMinLength"
	| "isMaxLength"
	| "isVehicleNumber"
	| "isInternationalPhoneFormat"
	| "isTodayOrBefore"
	| "isCaseInsensitiveMatch"
	| "isAlphanumeric"
	| "includesHealthcareSpokesperson"
	| "isMaxTwoHealthcareSpokespersons"
	| "isValidDob"
	| "isUnique"
	| "isValidatePropertyAllocation"
	| "isValidateAddress"
	| "isValidateCurrency"
	| "isNotIn"
	| "isAboveAge"
	| "isCarplate"
	| "match"
	| "doesNotMatch";

export type IInputValidatorFunction =
	| ((value: string, message: string) => string | undefined)
	| ((value: string, message: string, param?: any) => string | undefined);

export type IConstraint = [IInputValidatorNames, string] | [IInputValidatorNames, any, string];

// Email Validators
export const isValidEmail = (value: string): boolean => {
	const emailPattern =
		/(^$|(^([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
	return emailPattern.test(value);
};

// This will use a pattern to match the top-level domains (2-5 characters).
// For example, "test.user@example.coffee" will not match the regex below.
export const isValidOPGOEmail = (value: string): boolean => {
	const emailPattern =
		/(^$|(^([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$)/;
	return emailPattern.test(value);
};

//  ---------- INPUT VALIDATORS

const validationRule: { [key in IInputValidatorNames]: IInputValidatorFunction } = {
	isEmail: (value: string, message: string): string | undefined => {
		return !isValidEmail(value) ? message : undefined;
	},
	isOPGOEmail: (value: string, message: string): string | undefined => {
		return !isValidOPGOEmail(value) ? message : undefined;
	},
	isRequired: (value: string, message: string): string | undefined => {
		return isEmptyString(value) ? message : undefined;
	},
	isNumberString: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && isNaN(+value) ? message : undefined;
	},
	isNRIC: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !validateUinfin(value) ? message : undefined;
	},
	isNRICLocal: (value: string, message: string): string | undefined => {
		// This is to validate NRIC number (FOREIGN PREFIX will return error)
		return !isEmptyString(value) && (isForeignerByUinfin(value) || !validateUinfin(value)) ? message : undefined;
	},
	isNRICShape: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !validateIdentification("", value) ? message : undefined;
	},
	isLocalPhoneNumber: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !validateLocalPhoneNumber(value, false) ? message : undefined;
	},
	isMobileNumber: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !validateMobileNumber(value) ? message : undefined;
	},
	isVehicleNumber: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !validateVehicleNumber(value) ? message : undefined;
	},
	isNot: (value: string, message: string, param?: any): string | undefined => {
		return value === param ? message : undefined;
	},
	isNumber: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !isNumber(value) ? message : undefined;
	},
	isMoney: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !isMoney(value) ? message : undefined;
	},
	isZero: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && parseFloat(value) === 0 ? message : undefined;
	},
	isMaxValue: (value: string, message: string, param?: any): string | undefined => {
		return !isEmptyString(value) && parseFloat(value) >= +param ? message : undefined;
	},
	isInternationalPhoneFormat: (value: string, message: string): string | undefined => {
		return !isEmptyString(value) && !validateContactNumber(value) ? message : undefined;
	},
	isMinLength: (value: string, message: string, param?: any): string | undefined => {
		return !isEmptyString(value) && value.length < +param ? message : undefined;
	},
	isMaxLength: (value: string, message: string, param?: any): string | undefined => {
		return !isEmptyString(value) && value.length > +param ? message : undefined;
	},
	isCaseInsensitiveMatch: (value: string, message: string, strArray: string[] = []): string | undefined => {
		const lowerCaseValue = value.toLowerCase();
		for (const str of strArray) {
			if (lowerCaseValue === str.toLowerCase()) {
				return message;
			}
		}
		return undefined;
	},
	isAlphanumeric: (value: string, message: string): string | undefined => {
		const alphaNumericPattern = /^[a-zA-Z0-9]*$/;
		return !isEmptyString(value) && !alphaNumericPattern.test(value) ? message : undefined;
	},
	isUnique: (value: string, message: string, otherValues: string[]): string | undefined => {
		return otherValues.every((otherValue) => otherValue !== value) ? undefined : message;
	},
	isNotIn: (value: string | string[], message: string, otherValues: string[]): string | undefined => {
		if (Array.isArray(value)) {
			return value.length === 1 && !value.some((nric) => otherValues.includes(nric)) ? message : undefined;
		} else {
			return !otherValues.includes(value) ? message : undefined;
		}
	},
	isAboveAge: (value: string): string | undefined => {
		return undefined;
	},
	isCarplate: (value: string, message: string): string | undefined => {
		return isValidCarplate(value) ? undefined : message;
	},
	match: (value: string, message: string, regExp: RegExp): string | undefined => {
		return regExp.test(value) ? message : undefined;
	},
	doesNotMatch: (value: string, message: string, regExp: RegExp): string | undefined => {
		return !regExp.test(value) ? message : undefined;
	},
	// ===========================================================================
	// Validations that don't follow the required type
	// ===========================================================================
	isTodayOrBefore: (): string | undefined => {
		return undefined;
	},
	includesHealthcareSpokesperson: (): string | undefined => {
		return undefined;
	},
	isMaxTwoHealthcareSpokespersons: (): string | undefined => {
		return undefined;
	},
	isValidDob: (): string | undefined => {
		return undefined;
	},
	isValidatePropertyAllocation: (): string | undefined => {
		return undefined;
	},
	isValidateAddress: (): string | undefined => {
		return undefined;
	},
	isValidateCurrency: (): string | undefined => {
		return undefined;
	},
};

export const validateInputValue = (field: IFormField): string => {
	let errorMessage = "";

	/**
	 * Custom validation for date fields. Does not use the validationRule set above.
	 */
	if (field.type === "date") {
		const dateValue = field.value as IDateValue;
		const { year, month, day } = dateValue;

		if (field.constraints) {
			for (const constraint of field.constraints) {
				const [rule, message, age] = constraint;
				if (rule === "isRequired" && !day && !month && !year) {
					return message;
				}
				if (rule === "isAboveAge") {
					if ((!day && !month && !year) || !age) {
						continue;
					}
					if (isUserAgeBelowThreshold(new Date(+year, +month - 1, +day), +age)) {
						return message;
					}
				}

				if (rule === "isTodayOrBefore") {
					const momentDate = dateToMoment(dateToString(dateValue));

					if (!day && !month && !year) {
						continue;
					}

					if (!momentDate.isValid() || (year && +year < 1000)) {
						return "Invalid date";
					}

					if (momentDate.isAfter(moment().endOf("day"))) {
						return message;
					}
				}

				if (rule === "isValidDob") {
					if (day && month && year) {
						try {
							const isOfAge = LocalDate.of(+year, +month, +day).isBefore(LocalDate.now().minusYears(21));
							if (+dateValue.year < 1900 || !isOfAge) {
								return message;
							}
						} catch (e) {
							if (e instanceof DateTimeException) {
								return message;
							}
						}
					}
				}
			}
		}

		if (day || month || year) {
			if (!dateToMoment(dateToString(dateValue)).isValid() || (year && +year < 1000)) {
				return "Invalid date";
			}
		}
	}

	if (field.type === "unitNumber" && !field.disableValidation) {
		const unitValue = field.value as string[];
		const [floor, unit] = unitValue;

		if (field.constraints) {
			for (const constraint of field.constraints) {
				const [rule, message] = constraint;
				if (rule === "isRequired" && !floor && !unit) {
					return message;
				}
			}
		}

		if (!/^[0-9A-Z]{2,3}$/.exec(floor) || !/^[0-9A-Z]{2,5}$/.exec(unit)) {
			return "Invalid floor and unit number";
		}
	}

	if (field.constraints && !field.disableValidation) {
		for (const constraint of field.constraints) {
			if ("string" !== typeof field.value) {
				continue;
			}
			const trimmedValue = field.value.trim();
			// const trimmedValue = typeof field.value  === 'string' ? (field.value as string).trim() : field.value;

			if (constraint.length === 2) {
				// Validation without params
				const [rule, message] = constraint;
				const result = validationRule[rule](trimmedValue, message);
				// always return the first error encountered
				if (result) {
					errorMessage = result;
					break;
				}
			}

			if (constraint.length === 3) {
				// Validation with params
				const [rule, param, message] = constraint;
				const result = validationRule[rule](trimmedValue, message, param);
				// always return the first error encountered
				if (result) {
					errorMessage = result;
					break;
				}
			}
		}
	}

	return errorMessage;
};

export const validateRadioValue = (field: IFormField): string => {
	if (field.radioItems || field.radioBoxItems) {
		// trim the field value first
		if (typeof field.value === "string") {
			const trimmedValue = field.value.trim();
			const items = field.radioItems ?? field.radioBoxItems;
			const emptyInputField = items?.find((item) => item.label + ":" === trimmedValue);

			if (emptyInputField) {
				return emptyInputField.label; // return label as error field
			}
		}
	}

	if (field.constraints) {
		return validateInputValue(field);
	}

	return "";
};

export const validateFileInputValue = (field: IFormField): string => {
	const files = field.value as IFileObject[];

	for (const file of files) {
		if (file.hasError) {
			return "Attachment error"; // first field that has error
		}
	}

	return "";
};

export const validateCheckboxValue = (field: IFormField): string => {
	if (field.constraints && !field.disableValidation) {
		for (const constraint of field.constraints) {
			if (constraint.length === 2) {
				const [rule, message] = constraint;
				if (rule === "isRequired" && (field.value as string[]).length === 0) {
					return message;
				}
			} else if (constraint.length === 3) {
				const [rule, param, message] = constraint;
				const result = validationRule[rule](field.value as any, message, param);
				if (result) {
					return message;
				}
			}
			if (constraint.length === 3) {
				const [rule, value, message] = constraint;
				if (rule === "isMaxLength" && (field.value as string[]).length > value) {
					return message;
				}
			}
		}
	}

	return "";
};

export const validateAppointPersonValue = (field: IFormField): string => {
	if (field.constraints) {
		for (const constraint of field.constraints) {
			const [rule, message] = constraint;
			if (rule === "isRequired" && (field.value as string[]).length === 0) {
				return message;
			}
			if (
				rule === "includesHealthcareSpokesperson" &&
				(field.value as DoneeDO[]).every((person) => person.isCombinedFormSpokesperson === false)
			) {
				return message;
			}
			if (
				rule === "isMaxTwoHealthcareSpokespersons" &&
				(field.value as DoneeDO[]).filter((person) => person.isCombinedFormSpokesperson).length > 2
			) {
				return message;
			}
		}
	}
	return "";
};

export const validateAddressField = (field: IFormField): string => {
	if (field.constraints) {
		for (const constraint of field.constraints) {
			const [rule, param] = constraint;
			if (rule === "isValidateAddress") {
				if (!field.value[AddressSchemaFields.PROPERTY_TYPE]) {
					return "invalid property type";
				}

				if (!field.value[AddressSchemaFields.POSTAL]) {
					return "invalid postal code";
				}

				if (!field.value[AddressSchemaFields.BLOCK || !field.value[AddressSchemaFields.STREET]]) {
					return "invalid Address";
				}

				const floorUnit = field.value[AddressSchemaFields.FLOOR_UNIT] as string[];
				if (param === "isUnitRequired") {
					if (!floorUnit) {
						return "unit is required";
					}
				}
				if (floorUnit && floorUnit.length) {
					const [floor, unit] = floorUnit;
					if (!isFloorUnitValid(floor, unit)) {
						return "Invalid floor and unit number";
					}
				}
			}
		}
	}
	return "";
};

export const validateCurrency = (field: IFormField): string => {
	const [constraint] = field.constraints ?? [];
	const { value, currency } = field.value as ICurrencyValue;
	if (constraint?.[0] === "isValidateCurrency") {
		if (!field.value || !value) {
			return "Enter the amount. It must be at least 1.";
		}
		//pending UX confirmation
		if (!currency) {
			return "Please select the currency";
		}
	}
	return "";
};

export const isEmptyString = (value: string): boolean => {
	return value === "" || value === undefined || value === null;
};

export const isNumber = (val: string) => /^[0-9]+$/.test(val);

export const isMoney = (val: string) => /^(?!0\d)\d{0,11}(?:\.\d{1,2})?$/.test(val);
