import { isUserAgeBelowThreshold } from "app/common/date";
import { IFormFunctions, IFormSchema } from "app/hooks/useForm";
import { IBeneficiary } from "../Beneficiaries/data";
import { ExecutorCheckboxContent } from "./components/ExecutorCheckboxContent";
import { eligibleBeneficiaries } from "./utils";

export enum ExecutorsKey {
	SELECT_EXECUTOR = "selectExecutor",
	ADDITIONAL_EXECUTORS = "addExecutor",
	HAS_REPLACEMENT_EXECUTOR = "hasReplacementExecutor",
	REPLACEMENT_EXECUTOR = "replacementExecutor",
	OTHER_REPLACEMENT_EXECUTOR = "otherReplacementExecutor",
	REPLACEMENT_EXECUTOR_TO_TAKE_PLACE = "replacementExecutorToTakePlace",
}

export enum ExecutorsReplacementType {
	ANY = "anyExecutor",
	BOTH = "bothExecutors",
}

export const ExecutorsReplacementTypeDisplayMap = {
	[ExecutorsReplacementType.ANY]: "any executor",
	[ExecutorsReplacementType.BOTH]: "both executors",
};

export const SelectExecutorErrors = {
	MORE_THAN_2: "Select 2 persons at most.",
	ZERO_EXECUTOR: "Select or add at least 1 person.",
	ZERO_REPLACEMENT_EXECUTOR: "Select or add 1 person.",
	ADDED_BUT_NOT_SELECTED: "To confirm you do not want the person to be an executor, select Remove.",
	YES_OR_NO: "Select Yes or No.",
	SELECT_SUBSTITUTE: "Select who the replacement executor should be a substitute for.",
};

export interface IExecutor {
	type?: string;
	key?: string;
	details?: IBeneficiary;
}

export const ExecutorSchema = (
	form: IFormFunctions,
	executorsList: Map<string, IExecutor>,
	selectList: string[],
	replacementExecutorList: Map<string, IExecutor>,
	onRemove?: (key: string) => void,
): IFormSchema => {
	return {
		[ExecutorsKey.SELECT_EXECUTOR]: {
			type: "checkbox",
			checkboxItems: Array.from(executorsList.keys())?.flatMap((executor) => {
				const executorDetails = executorsList.get(executor);
				if (!executorDetails) {
					return [];
				}
				if (executorDetails.type === "additionalExecutors") {
					if (!executorDetails.key) {
						return [];
					}
					return {
						label: "Someone else",
						value: executorDetails.key,
						overwriteRenderedContent: (
							<ExecutorCheckboxContent
								form={form}
								selectList={selectList}
								index={executorDetails.key}
								key={executorDetails.key}
							/>
						),
						onRemove: () => onRemove && onRemove(executorDetails.key ?? ""),
					};
				}
				if (!executorDetails.details) {
					return [];
				}
				const { year, month, day } = executorDetails.details.dob;
				if (!isUserAgeBelowThreshold(new Date(+year, +month, +day), 21)) {
					return { label: executorDetails.details.name, value: executorDetails.details.nric };
				}
				return [];
			}),
		},
		[ExecutorsKey.ADDITIONAL_EXECUTORS]: {
			type: "addExecutors",
		},
		[ExecutorsKey.HAS_REPLACEMENT_EXECUTOR]: {
			type: "radio",
			radioBoxItems: [
				{ label: "Yes", value: "Yes" },
				{ label: "No", value: "No" },
			],
		},
		[ExecutorsKey.REPLACEMENT_EXECUTOR_TO_TAKE_PLACE]: {
			type: "radio",
			radioBoxItems: [
				{
					label: "any executor",
					value: ExecutorsReplacementType.ANY,
					subtitle:
						"The replacement is a substitute for either person who doesn’t want or can’t be executors.",
				},
				{
					label: "both executors",
					value: ExecutorsReplacementType.BOTH,
					subtitle:
						"The replacement only takes over when both people don’t want to be or can’t be executors. They then become the only executor.",
				},
			],
		},
		[ExecutorsKey.REPLACEMENT_EXECUTOR]: {
			type: "radio",
			radioBoxItems: eligibleBeneficiaries(replacementExecutorList),
		},
	};
};
