interface PathConfig {
	path: string;
	skipMainPath?: boolean;
}

export const hideNotificationBannerV2From: PathConfig[] = [
	{ path: "/find-a-service/acp", skipMainPath: true }, // ACP 3.0 Flow
	{ path: "/find-a-service/lpa-acp", skipMainPath: true }, // LPA ACP Combined Flow
	{ path: "/find-a-service/acp-only", skipMainPath: true }, // ACP 2.0 Flow
	{ path: "/find-a-tool/learning-tool-write-a-will", skipMainPath: true }, // E Wills Flow
	{ path: "/find-a-service/apply-for-free-hdb-parking", skipMainPath: true }, // HDB Car Parking Flow
	{ path: "/death-or-stillbirth-certificate" }, // eDC Flow
	{ path: "/vault" }, // Vault Flow
];

export const hideICAMaintenanceBannerV2From: PathConfig[] = [
	{ path: "/find-a-service/acp" }, // ACP 3.0 Flow
	{ path: "/find-a-service/lpa" }, // LPA Flow
	{ path: "/find-a-service/start-preparing-lpa-or-acp" }, // LPA OR ACP Flow
	{ path: "/find-a-service/lpa-acp" }, // LPA ACP Combined Flow
	{ path: "/find-a-service/acp-only" }, // ACP 2.0 Flow
	{ path: "/find-a-tool/learning-tool-write-a-will" }, // E Wills Flow
	{ path: "/find-a-service/apply-for-free-hdb-parking" }, // HDB Car Parking Flow
	{ path: "/vault" }, // Vault Flow
];

export const shouldHideBanner = (currentPath: string, paths: PathConfig[]): boolean => {
	return paths.some(({ path, skipMainPath }) => {
		if (skipMainPath && currentPath === path) {
			return false;
		}
		return currentPath.startsWith(path);
	});
};
