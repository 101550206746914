import { Text } from "@lifesg/react-design-system/text";
import { dateToString, isUserAgeBelowThreshold } from "app/common/date";
import { dateToString as dateValueToString } from "app/components/basic/Date/utils";
import AlertBanner from "app/components/composites/AlertBanner";
import { IFormSchema, TFormValue } from "app/hooks/useForm";
import { IEWillsModalData } from "app/modules/eWills/components/AddItemGroup/types";
import { IDateValue } from "common/api/interface";
import { FormSegmentLabel } from "../common-styles";

export enum BeneficiaryKeys {
	NAME = "name",
	NRIC = "nric",
	DOB = "dob",
	RELATIONSHIP = "relationship",
	OTHER_RELATIONSHIP = "otherRelationship",
}

export type IBeneficiaryHistoryMap = {
	/**
	 * holds history of added beneficiaries
	 * @param nric nric of beneficiary
	 * @param name name of beneficiary
	 * @param index index in order of which the beneficiary is added
	 */
	[nric: string]: { name: string; index: number };
};

export type IBeneficiary = {
	name: string;
	nric: string;
	relationship: string;
	otherRelationship: string;
	dob: {
		year: string;
		month: string;
		day: string;
	};
};

export enum Relationship {
	SPOUSE = "Spouse",
	CHILD = "Child",
	PARENT = "Parent",
	SIBLING = "Sibling",
	GRANDCHILD = "Grandchild",
	STEP_CHILD = "Stepchild",
	NIECE_OR_NEPHEW = "Niece or nephew",
	FRIEND = "Friend",
	OTHER = "Other",
}

export const BeneficiaryData: IEWillsModalData = {
	[BeneficiaryKeys.NAME]: {
		label: "Full name (as in NRIC)",
		field: {
			type: "text",
			constraints: [["isRequired", "Enter their full name."]],
		},
		width: "stretch-container",
	},
	[BeneficiaryKeys.NRIC]: {
		label: "NRIC or birth certificate number",
		field: {
			type: "nric",
			constraints: [
				["isRequired", "Enter their NRIC or birth certificate number."],
				["isNRIC", "Invalid NRIC or birth certificate number. Check that the right ID number was entered."],
			],
			transformUppercase: true,
		},
		isUnique: { errorMessage: "You\u2019ve already added this person as a beneficiary. Choose someone else." },
	},
	[BeneficiaryKeys.DOB]: {
		label: "Date of birth",
		field: { type: "date", constraints: [["isRequired", "Select their birthdate."]] },
		warning: {
			warningMessage: () => (
				<AlertBanner type="warning">
					<p style={{ marginBottom: "0.5rem" }}>
						Those who are under 21 years old will not receive their inheritance until their 21st birthday.
					</p>
					<p>Until then, their inheritance will be managed by your executor.</p>
				</AlertBanner>
			),
			warningCallback: (value: TFormValue) => {
				const dateString = dateValueToString(value as IDateValue);
				return dateString ? isUserAgeBelowThreshold(new Date(dateString)) : false;
			},
		},
		minDate: "1900-01-01",
		maxDate: dateToString(new Date()),
	},
	[BeneficiaryKeys.RELATIONSHIP]: {
		label: "Relationship to you",
		field: {
			type: "dropdown",
			dropdownItems: Object.values(Relationship).map((value) => {
				return {
					label: value,
					value: value,
				};
			}),
			constraints: [["isRequired", "Select their relationship to you."]],
		},
		dependencies: [
			{
				fields: [BeneficiaryKeys.OTHER_RELATIONSHIP],
				values: [Relationship.OTHER],
			},
		],
	},
	[BeneficiaryKeys.OTHER_RELATIONSHIP]: {
		label: (
			<FormSegmentLabel>
				<Text.H4 weight="semibold">How is this person related to you?</Text.H4>
				<Text.BodySmall>This makes it easier to identify the right person.</Text.BodySmall>
			</FormSegmentLabel>
		),
		field: {
			type: "text",
			constraints: [["isRequired", "Enter their relationship to you (example: mentor, friend\u2019s child)."]],
		},
		regexPattern: /[^a-z0-9-\s]/gi,
	},
};

export const BeneficiariesSchema: IFormSchema = {
	beneficiary: {
		type: "beneficiary",
		constraints: [["isRequired", "Add at least 1 person."]],
	},
};
