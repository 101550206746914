export enum TypesOfCookies {
	EXPIRY_KEY = "expiry",
	NAME_KEY = "name",
	NRIC_KEY = "nric",
	ONBOARD_KEY = "onboard",
	GTM_KEY = "gtm",
	WOGAA_KEY = "wogaa",
	KOA_CSRF = "koa-csrf",
	TOKEN_KEY = "token",
	EXPIRED_KEY = "expired",
	MOL_TOKEN_KEY = "MOLToken",
	LPA_STATUS_KEY = "LpaStatus",
	OPGO_PERMISSION = "opgoPermissionGiven",
	ACP_PERMISSION = "acpPermissionGiven",
}
