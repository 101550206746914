import translations from "app/assets/files/key-value-translations/error500.json";
import BasicLink from "app/components/basic/Link";
import Segment from "app/components/page/Segment";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import Head from "next/head";
import * as React from "react";
import { Grid } from "semantic-ui-react";
import "./ErrorPages.scss";

const Error500 = (): JSX.Element => {
	const currentUrl: string[] = window.location.pathname.split("/");
	const isVault = currentUrl[1] === "vault" ? true : false;
	const [language] = useGlobalLanguageState();

	const getTranslatedCopy = (key: string): string => {
		const _translations = translations[language];
		return _translations[key as keyof typeof _translations].data;
	};

	const getSendEmailInstructionElement = (): JSX.Element => {
		const translatedCopies = getTranslatedCopy("SendEmailInstruction").split("enquiries@mylegacy.gov.sg");
		return (
			<>
				{translatedCopies[0]}
				<BasicLink
					type="inline-link"
					openInNewPage={true}
					disablePrefetch={true}
					href={"mailto:enquiries@mylegacy.gov.sg?subject=UnexpectedError"}
					name={"enquiries@mylegacy.gov.sg"}
					id={`unexpected-error-page-mail`}
				/>
				{translatedCopies[1]}
			</>
		);
	};

	return (
		<Segment>
			<Head>
				<title>
					{getTranslatedCopy("PageTitle")} - {isVault ? "Legacy vault" : "My Legacy"}
				</title>
			</Head>
			<Grid>
				<Grid.Row>
					<Grid.Column className="no-margin no-padding" mobile={12} tablet={12} computer={12}>
						<div className="page__unexpected-error">
							<div className="error-container">
								<div className={`error-header${isVault ? "-vault" : ""}`}>
									<div className="subtitle">{getTranslatedCopy("ErrorCode500")}</div>
									<h2>{getTranslatedCopy("UnexpectedErrorHasOccurred")}</h2>
								</div>
								<div className="error-icon">
									<img
										className="error-image-mobile"
										src="/images/error-technical.png"
										alt={getTranslatedCopy("Error500Image")}
									/>
								</div>
								<div className="error-description">
									<p>
										{getTranslatedCopy("PleaseTryAgainInAWhile")}
										<br />
										<br />
										{getSendEmailInstructionElement()}
									</p>
								</div>
							</div>
							<img
								className="error-image-desktop"
								src="/images/error-technical.png"
								alt={getTranslatedCopy("Error500Image")}
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Error500;
