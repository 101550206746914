import { IFormSchema } from "app/hooks/useForm";
import { BeneficiaryKeys, Relationship } from "../../../Beneficiaries/data";

export enum NricErrors {
	DUPLICATE_NRIC_ERROR_MESSAGE = "You’ve already added them as an executor or a beneficiary. Choose someone else to add.",
	SAME_NRIC_AS_APPLICANT_ERROR_MESSAGE = "An executor is someone who carries out what your will says after you die. Choose someone else instead.",
	SAME_NRIC_AS_REPLACEMENT_EXECUTOR_ERROR_MESSAGE = "You've already selected this person as a replacement executor. Choose someone else. Or change the replacement executor instead.",
	INVALID_NRIC = "Invalid NRIC number.",
	NO_NRIC = "Enter their NRIC.",
}

export const AdditionalExecutorSchema = (applicantUinfin: string): IFormSchema => {
	return {
		[BeneficiaryKeys.NAME]: {
			type: "text",
			constraints: [["isRequired", "Enter their full name."]],
		},
		[BeneficiaryKeys.NRIC]: {
			type: "nric",
			constraints: [
				["isRequired", NricErrors.NO_NRIC],
				["isNRIC", NricErrors.INVALID_NRIC],
				["isNot", applicantUinfin, NricErrors.SAME_NRIC_AS_APPLICANT_ERROR_MESSAGE],
			],
		},
		[BeneficiaryKeys.DOB]: {
			type: "date",
			constraints: [
				["isRequired", "Select their birthdate."],
				["isAboveAge", "An executor must be at least 21 years old. Choose someone else instead.", "21"],
			],
		},
		[BeneficiaryKeys.RELATIONSHIP]: {
			type: "dropdown",
			constraints: [["isRequired", "Select their relation to you."]],
			dropdownItems: Object.values(Relationship).map((value) => {
				return {
					label: value,
					value,
				};
			}),
		},
		[BeneficiaryKeys.OTHER_RELATIONSHIP]: {
			type: "text",
			constraints: [["isRequired", "Enter their relationship with you. For example, friend, business partner."]],
		},
	};
};
