import { GetTrustedPersonDO, TrustedConnectionStatusTypes, TrustedPersonDO } from "app/common/api/trustedConnection";
import * as contracts from "app/common/api/trustedConnection/routes";
import Dropdown, { IDropdownItem, IDropdownItemTemplate } from "app/components/basic/Dropdown";
import { throwToErrorBoundary } from "app/components/composites/ErrorBoundary";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import _ from "lodash";
import * as React from "react";
import { DropdownProps } from "semantic-ui-react";
import NewTrustedPerson from "./NewTrustedPerson";
import PersonItem, { IPersonItem } from "./PersonItem";

export interface IPersonComboBoxProps {
	api_fetchTrustedPeople?: () => Promise<GetTrustedPersonDO[]>;
	api_createTrustedPerson?: (newTrustedPerson: TrustedPersonDO) => Promise<contracts.postTrustedPerson.Response>;
	sharedIDs?: number[];
	userList?: IDropdownItemTemplate[];
	value: number[];
	selectItem?: (item: any) => void;
	disabled?: boolean;
	largeTitle?: boolean;
	error?: string;
}

interface IState {
	personList: IDropdownItemTemplate[];
	searchTerm: string;
	showModal: boolean;
	personListForRender: IDropdownItemTemplate[];
	showEmptyMessage: boolean;
}

class PersonComboBox extends React.Component<IPersonComboBoxProps, IState> {
	public state: IState = {
		personList: [],
		searchTerm: "",
		showModal: false,
		personListForRender: [],
		showEmptyMessage: false,
	};

	public componentDidMount = async (): Promise<void> => {
		await this.populatePersonList();
		await this.initAlreadySharedUsers();
	};

	public componentDidUpdate = async (prevProps: IPersonComboBoxProps): Promise<void> => {
		if (this.props.sharedIDs !== prevProps.sharedIDs) {
			await this.initAlreadySharedUsers();
		}
	};

	public render = (): JSX.Element => (
		<>
			<Dropdown
				hideIcon={true}
				largeTitle={this.props.largeTitle}
				clearSearch={this.clearSearch}
				additionLabel='Add "' // <" as a new connection> is in SCSS file ::after tag
				allowAdditions={this.showAddition()}
				header={this.renderHeader()}
				onAddItem={this.beginAddNewPerson}
				search={this.handleSearchChange}
				multiple={true}
				name="trusted-person"
				title="Trusted Persons"
				items={this.state.personListForRender || []}
				selectedValue={this.props.value}
				onSearchChange={this.onSearch}
				selectItem={this.selectPerson}
				renderItem={this.renderItem}
				disabled={this.props.disabled}
				placeholder={this.props.value.length === 0 ? "Enter Name or NRIC" : "Add more people..."}
			/>
			{this.state.showModal && (
				<NewTrustedPerson
					showModal={this.state.showModal}
					initialSearchQuery={this.state.searchTerm}
					initialPersonList={this.state.personList}
					selectedPersons={this.props.value}
					saveAction={this.addNewlyCreatedPerson}
					onCloseHandler={this.closeModal}
					selectMatchingHandler={this.selectMatchingHandler}
				/>
			)}
			{this.props.error !== "" && <ErrorMessage>{this.props.error}</ErrorMessage>}
		</>
	);

	private clearSearch = (): void => {
		this.setState({ searchTerm: "" });
	};

	private addNewlyCreatedPerson = async (
		newPerson: TrustedPersonDO,
	): Promise<contracts.postTrustedPerson.Response> => {
		let createdPerson: contracts.postTrustedPerson.Response | null = null;

		if (this.props.api_createTrustedPerson) {
			try {
				createdPerson = await this.props.api_createTrustedPerson(newPerson);
			} catch (err) {
				// throw err to NewTrustedPerson.tsx
				throw err;
			}
		}

		if (createdPerson && createdPerson.id) {
			await this.populatePersonList();
			await this.initAlreadySharedUsers();
			const selectedPersons: number[] = this.props.value;
			selectedPersons.push(createdPerson.id);
			if (this.props.selectItem) {
				this.props.selectItem(selectedPersons);
			}
		}

		return createdPerson!;
	};

	private selectPerson = (newPersonList: number[]): void => {
		const personAlreadyShared = newPersonList.filter((id: number) => this.checkIfAlreadyShared(id))[0];
		if (!personAlreadyShared) {
			this.setState({ searchTerm: "" });
			if (this.props.selectItem) {
				this.props.selectItem(newPersonList);
			}
		}
	};

	private populatePersonList = async (): Promise<void> => {
		let data: GetTrustedPersonDO[] = [];

		if (this.props.api_fetchTrustedPeople) {
			try {
				data = await this.props.api_fetchTrustedPeople();
			} catch (err: any) {
				this.setState(throwToErrorBoundary(err));
			}
		}

		// list of all trusted people
		const personList: IDropdownItemTemplate[] = data.map(
			(person: GetTrustedPersonDO): IDropdownItemTemplate => ({
				label: person.nickname,
				value: person.id,
				contentData: {
					...person,
				},
			}),
		);

		// list of trusted people who are either invited or accepted
		const personListForRender: IDropdownItemTemplate[] = personList.filter((person: IDropdownItemTemplate): any => {
			return (
				person.contentData.status === TrustedConnectionStatusTypes.INVITED ||
				person.contentData.status === TrustedConnectionStatusTypes.ACCEPTED
			);
		});

		this.setState({ personList, personListForRender });
	};

	private initAlreadySharedUsers = async (): Promise<void> => {
		const personListForRender: IDropdownItemTemplate[] = this.state.personListForRender.map(
			(item: IDropdownItemTemplate): IDropdownItemTemplate => {
				item.disabled = this.checkIfAlreadyShared(item.value! as number) !== undefined;
				item.contentData.shared = this.checkIfAlreadyShared(item.value! as number) !== undefined;
				return item;
			},
		);

		this.setState({ personListForRender });
	};

	private checkIfAlreadyShared = (id: number): undefined | number => {
		const sharedIDs: number[] | undefined = this.props.sharedIDs;
		return sharedIDs && sharedIDs.filter((sharedID: number) => sharedID === id)[0];
	};

	private handleSearchChange = (options: any[], query: string): any[] => {
		const searchText = new RegExp(_.escapeRegExp(_.trim(query)), "i");
		const results = options.filter((item: IDropdownItem) => {
			const person: IPersonItem = item.content!.props;
			return (
				searchText.test(person.nickname) || searchText.test(person.name || "") || searchText.test(person.nric)
			);
		});
		const isEmpty = results.length === 0;
		if (this.state.showEmptyMessage !== isEmpty) {
			this.setState({ showEmptyMessage: isEmpty });
		}
		return results;
	};

	private renderPersonItem = (person: IPersonItem): JSX.Element => (
		<PersonItem
			nickname={person.nickname}
			name={person.status === TrustedConnectionStatusTypes.ACCEPTED ? person.name : ""}
			nric={person.nric}
			shared={person.shared}
		/>
	);

	private beginAddNewPerson = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		const listWithRemovedAddPerson = this.props.value.filter((id: number | string) => id !== data.value);
		if (this.props.selectItem) {
			this.props.selectItem(listWithRemovedAddPerson);
		}
		this.setState({ searchTerm: data.value as string }, () => {
			this.openModal();
		});
	};

	private showAddition = (): boolean => {
		const searchCondition = (dropdownItem: IDropdownItemTemplate): boolean => {
			const { nickname, nric } = dropdownItem.contentData;
			const search = this.state.searchTerm.toLowerCase();
			if (nickname.toLowerCase() === search || nric.toLowerCase() === search) {
				return true;
			}
			return false;
		};

		if (this.state.personListForRender.find(searchCondition)) {
			return false;
		}
		return true;
	};

	private renderHeader = (): JSX.Element | undefined => {
		if (this.state.searchTerm && this.state.showEmptyMessage) {
			return (
				<span className="message" onClick={this.openModal}>
					You do not have existing connections with this name/NRIC
				</span>
			);
		}
		return undefined;
	};

	private renderItem = (personData: IPersonItem): JSX.Element => {
		return this.renderPersonItem(personData);
	};

	private onSearch = (query: string): void => {
		this.setState({ searchTerm: _.trim(query) });
	};

	private closeModal = (): void => {
		this.setState({ showModal: false, searchTerm: "" });
	};

	private openModal = (): void => {
		this.setState({ showModal: true });
	};

	private selectMatchingHandler = (id: number): void => {
		const selectedPersons: number[] = this.props.value;
		selectedPersons.push(id);
		if (this.props.selectItem) {
			this.props.selectItem(selectedPersons);
		}
	};
}

export default PersonComboBox;
