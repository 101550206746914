import { ExclamationCircleFillIcon, BinIcon } from "@lifesg/react-icons";
import FileManagementAPI from "app/api/fileManagement";
import { FileScanResult } from "app/common/api/fileManagement";
import InlineLoader from "app/components/widgets/InlineLoader";
import { getSizeWithUnit } from "app/utils/file";
import * as React from "react";
import Modal from "../Modal";
import { IFileObject } from "./FileInput";

interface IProps {
	index: number;
	fileObject: IFileObject;
	onRemove: (index: number) => void;
	onUploadComplete: (file: IFileObject, index: number, hasError: boolean) => void;
	bindRef?: (ref: HTMLDivElement) => void;
}

interface IState {
	loaded: number;
	error: any;
	showDeleteModal: boolean;
	showLoader: boolean;
}

// each component of this is a single uploaded file
class FileInputRow extends React.Component<IProps, IState> {
	public state: IState = {
		loaded: 0,
		error: null,
		showDeleteModal: false,
		showLoader: false,
	};

	public rowRef: any = React.createRef<any>();

	public componentDidMount = async (): Promise<void> => {
		if (this.props.bindRef) {
			this.props.bindRef(this.rowRef);
		}
		if (this.props.fileObject && this.props.fileObject.file) {
			await this.uploadFile();
		}
	};

	public render = (): JSX.Element => {
		const { index, fileObject } = this.props;
		return (
			<div
				className={`input__file__uploaded file-${index}`}
				id={`file-${index}`}
				key={`file-${index}`}
				tabIndex={-1}
				ref={this.handleRef}
			>
				<div className="input__file__uploaded__details">
					<div className="input__file__uploaded__details--left">
						<div className="input__file__uploaded__name">{fileObject && fileObject.displayName}</div>
						<div className="input__file__uploaded__size">
							{fileObject && getSizeWithUnit(fileObject.size)}
						</div>
					</div>
					{!this.state.showLoader && (
						<div
							className="input__file__uploaded__remove"
							onClick={this.onRemove}
							id={`remove__file-${index}`}
						>
							<BinIcon className="input__file__uploaded__remove__icon" />
						</div>
					)}
					{this.state.showLoader && (
						<div className="input__file__uploaded__loader">
							<InlineLoader type="dark" />
						</div>
					)}
				</div>
				{this.renderDeleteModal()}

				{this.state.error && (
					<div className="input__file__uploaded__error">
						<ExclamationCircleFillIcon className="input__file__uploaded__error__icon" />
						{this.state.error}
						{FileScanResult.Malicious !== this.state.error && (
							<a className="input__file__uploaded__error__retry" onClick={this.uploadFile}>
								Try again.
							</a>
						)}
					</div>
				)}
			</div>
		);
	};

	private handleRef = (ref: HTMLDivElement): void => {
		this.rowRef = ref;
	};

	private uploadFile = async (): Promise<void> => {
		// reset error
		this.setState({ showLoader: true, error: null });
		const { fileObject } = this.props;

		try {
			const response = await FileManagementAPI.getSignedPostForm(fileObject.file!.type);

			const s3UniqueName = response.uniqueFileName;

			const callback = async (loaded: number): Promise<void> => {
				this.setState({ loaded });
			};

			await FileManagementAPI.putObjectToBucket(response, fileObject.file!, callback);

			const result = await FileManagementAPI.scanFile(s3UniqueName);

			const { displayName, size } = this.props.fileObject;
			this.setState({ showLoader: false });

			if (result !== FileScanResult.Safe) {
				throw result;
			}
			const uploadedObject: IFileObject = { displayName, s3UniqueName, size, isSubmitting: false };
			this.props.onUploadComplete(uploadedObject, this.props.index, false);
		} catch (e) {
			const error =
				e === FileScanResult.Malicious
					? "File uploaded has failed our anti-virus scan. Please upload another file. "
					: "There was an error uploading the file. ";
			this.setState({ loaded: 0, error, showLoader: false });
			this.props.onUploadComplete({ ...this.props.fileObject, isSubmitting: false }, this.props.index, true);
		}
	};

	private onRemove = (): void => {
		this.setState({ showDeleteModal: true });
	};

	private closeDeleteModalCallback = (): void => {
		this.setState({ showDeleteModal: false });
	};

	private removeFile = async (): Promise<void> => {
		this.props.onRemove(this.props.index);
		this.setState({ showDeleteModal: false });
	};

	private renderDeleteModal = (): JSX.Element => (
		<Modal
			id="modal__remove-file"
			type="message"
			title="Are you sure you want to remove?"
			subTitle="You are removing this file."
			isOpen={this.state.showDeleteModal}
			closeCallback={this.closeDeleteModalCallback}
			button1={["Cancel", "secondary", this.closeDeleteModalCallback]}
			button2={["Remove", "primary", this.removeFile]}
		/>
	);
}

export default FileInputRow;
