import { ArrowRightIcon } from "@lifesg/react-icons/arrow-right";
import { DeathRecordType } from "app/common/api/deathCert";
import Header from "app/components/basic/Header";
import Segment from "app/components/page/Segment";
import { FormKeys } from "app/components/templates/DeathCert/Forms/schemas";
import PublicPage from "app/components/templates/PublicPage";
import { getGTMFormName } from "app/hooks/ga/death-or-stillbirth-certificate";
import { Form, IFormSchema, useForm } from "app/hooks/useForm";
import { GTMUtils } from "app/utils/analytics/gtm-helper";
import { GTMEvent, GTMFormBasicParams } from "app/utils/analytics/types";
import { useCallback, useContext } from "react";
import { Grid } from "semantic-ui-react";
import { DeathCertFormContext, DeathCertFormData } from "./DeathCertFormContext";
import "./style.scss";

const DEATH = "Death certificate";
const STILLBIRTH = "Stillbirth certificate";

const PAGE_TITLE = "Download death or stillbirth certificate";

const fieldSchema: IFormSchema = {
	[FormKeys.CERT_TYPE]: {
		type: "radio",
		radioBoxItems: [
			{ label: DEATH, value: DeathRecordType.Death },
			{ label: STILLBIRTH, value: DeathRecordType.Stillbirth },
		],
	},
};

const SelectTypeForm = (): JSX.Element => {
	const { goNext, formResponses } = useContext<DeathCertFormData>(DeathCertFormContext);
	const { form } = useForm(fieldSchema, formResponses);
	const { getValue } = form;

	const nextAction = useCallback((): void => {
		goNext({ [FormKeys.CERT_TYPE]: getValue(FormKeys.CERT_TYPE) });

		// GA4 event
		GTMUtils.fireEvent<GTMFormBasicParams>(GTMEvent.FORM_START, {
			form_name: getGTMFormName(getValue(FormKeys.CERT_TYPE) as DeathRecordType),
		});
	}, [getValue, goNext]);

	const RightArrow = (props: any): JSX.Element => (
		<div {...props}>
			<ArrowRightIcon />
		</div>
	);

	return (
		<PublicPage title={PAGE_TITLE}>
			<Segment paddingBottom={104}>
				<Header title={PAGE_TITLE} />
				<Grid className="death-cert-form">
					<Grid.Row columns={12}>
						<Grid.Column className="no-margin" mobile={12} tablet={4} computer={4}>
							<h4>Select one</h4>
							<Form.RadioBox columns={1} form={form} field={FormKeys.CERT_TYPE} />
							<div className="death-cert-form__button-bar death-cert-form__button-bar--single">
								<Form.Button
									form={form}
									name="Next"
									type="primary"
									action={nextAction}
									IconRight={RightArrow}
									disabled={!getValue(FormKeys.CERT_TYPE)}
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</PublicPage>
	);
};

export default SelectTypeForm;
