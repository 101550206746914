import { ICircleFillIcon } from "@lifesg/react-icons/i-circle-fill";
import Button from "app/components/basic/Button";
import { checkIfPageHasTranslation } from "app/utils/internationalization/constants";
import {
	AvailableLanguages,
	LanguageISOCode,
	useGlobalLanguageState,
} from "app/utils/internationalization/GlobalLanguageProvider";
import { LoginContext } from "app/utils/login";
import { useContext, useEffect, useState } from "react";
import "./styles.scss";

const selectableLanguages: { [key in AvailableLanguages]: string } = {
	[AvailableLanguages.en]: "English",
	[AvailableLanguages.zh]: "中文",
	[AvailableLanguages.ml]: "Melayu",
	[AvailableLanguages.ta]: "தமிழ்",
};

const readThisIn: { [key in AvailableLanguages]: string } = {
	[AvailableLanguages.en]: "Read this in:",
	[AvailableLanguages.zh]: "选择您的语言:",
	[AvailableLanguages.ml]: "Baca ini dalam:",
	[AvailableLanguages.ta]: "இதனைப் படிக்க விரும்பும் மொழி:",
};

const noTranslationMessage: { [key in AvailableLanguages]: string } = {
	[AvailableLanguages.en]: "This page is only available in English.",
	[AvailableLanguages.zh]: "此页只以英文显示。",
	[AvailableLanguages.ml]: "Halaman ini hanya tersedia dalam bahasa Inggeris.",
	[AvailableLanguages.ta]: "இந்தப் பக்கம் ஆங்கிலத்தில் மட்டுமே உள்ளது.",
};

type clickHandler = () => void;

interface IProps {
	id: string;
	className?: string;
	hideWhenTranslationUnavailable?: boolean;
	backgroundClassName?: string;
	error?: boolean;
}

const LanguageSelector = (props: IProps): JSX.Element => {
	const [, selectedLanguage, updateLanguage] = useGlobalLanguageState();
	const [hasTranslation, setHasTranslation] = useState<boolean>(true);
	const [background, setBackground] = useState<string>("segment__white");
	const isLoggedIn = useContext<boolean>(LoginContext);

	const { pathname } = window.location;

	useEffect(() => {
		setHasTranslation(checkIfPageHasTranslation(pathname, props.error));

		// set background color for the segment
		if (!props.backgroundClassName && pathname.match(/vault\/?$/g) && isLoggedIn && props.error === undefined) {
			setBackground("segment__blue");
			return;
		}
		setBackground("segment__white");
	}, [props.backgroundClassName, pathname, isLoggedIn, props.error]);

	useEffect(() => {
		/**
		 * document.title does not update immediately if a feature toggle is used.
		 * Use a MutationObserver to setHasTranslation on title update
		 */
		const observer = new MutationObserver(() => {
			setHasTranslation(checkIfPageHasTranslation(pathname, props.error));
		});

		if (!document.title) {
			document.title = "";
		}

		const title = document.querySelector("title");
		if (title) {
			observer.observe(title, { subtree: true, characterData: true, childList: true });
		}
		return () => observer.disconnect();
	}, [pathname, props.error]);

	const renderLanguageSelector = (): JSX.Element => {
		const selectorButtons: JSX.Element[] = [];

		const handleSelectLanguage =
			(languageKey: AvailableLanguages): clickHandler =>
			() => {
				updateLanguage(languageKey);

				if (window.location.pathname.includes("frequently-asked-questions")) {
					const cleanUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
					window.history.pushState({ path: cleanUrl }, "", cleanUrl);
				}
			};

		Object.keys(selectableLanguages).forEach((key: string, index: number) => {
			if (selectedLanguage !== key) {
				const buttonText = selectableLanguages[key] as string;
				selectorButtons.push(
					<Button
						key={`${props.id}__${key}`}
						id={`${props.id}__${key}`}
						name={buttonText}
						onClick={handleSelectLanguage(key as AvailableLanguages)}
						type="link"
					/>,
				);
				if (selectorButtons.length < 5) {
					selectorButtons.push(
						<div key={`${props.id}__divider-${index}`} className="language-selector__divider">
							|
						</div>,
					);
				}
			}
		});
		return (
			<div className={`segment ${background}`}>
				<div className="segment__container">
					<div id={props.id} className={`language-selector ${props.className || ""}`}>
						<div className="language-selector__helper-text">{readThisIn[selectedLanguage]}</div>
						<div className="language-selector__buttons-wrapper">{selectorButtons}</div>
					</div>
				</div>
			</div>
		);
	};

	const renderTranslationUnavailable = (): JSX.Element => {
		if (props.hideWhenTranslationUnavailable || selectedLanguage === AvailableLanguages.en) {
			return <></>;
		}
		return (
			<div className={`segment ${background}`} lang={LanguageISOCode[selectedLanguage]}>
				<div className="segment__container">
					<div
						id={props.id + "__no-translation"}
						className={`language-selector language-selector--single-row ${props.className || ""}`}
					>
						<div className="language-selector__icon-wrapper">
							<ICircleFillIcon />
						</div>
						<div>{noTranslationMessage[selectedLanguage]}</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{hasTranslation && renderLanguageSelector()}
			{!hasTranslation && renderTranslationUnavailable()}
		</>
	);
};

export default LanguageSelector;
