import { MagnifierIcon } from "@lifesg/react-icons/magnifier";
import "./SearchbarLabel.scss";

const SearchbarLabel = (): JSX.Element => {
	return (
		<div className="searchbar-label">
			<MagnifierIcon className="searchbar-label__icon" />
			<div className="searchbar-label__text">Search</div>
		</div>
	);
};

export default SearchbarLabel;
